import {
    RESET_PAGE_SCROLL,
    SET_CALCULATED_SCROLL,
    SET_NAVIGATION_ANCHORS,
    SET_PAGE_HEIGHT,
    TOGGLE_DDS_MENU,
    TOGGLE_DP_MENU,
    TOGGLE_PS_MENU,
    TOGGLE_HOME_MENU,
    TOGGLE_OVERLAY,
    TOGGLE_PORTFOLIO_MENU
} from "../actions/pageActions";

const defaultState = {
    calculatedScroll:0,
    displayOverlay: false,
    menuDpExpanded: true,
    menuPsExpanded: true,
    menuDdsExpanded: true,
    menuHomeExpanded: true,
    menuPortfolioExpanded: true,
    navigationAnchors:[],
    pageHeight:0
};

const page = (state = defaultState, action = undefined) => {
    const {type} = action;

    switch (type) {
        case TOGGLE_HOME_MENU: {
            return {
                ...state,
                menuHomeExpanded: action.value
            }
        }
        case TOGGLE_DDS_MENU: {
            return {
                ...state,
                menuDdsExpanded: action.value
            }
        }
        case TOGGLE_DP_MENU: {
            return {
                ...state,
                menuDpExpanded: action.value
            }
        }
        case TOGGLE_PS_MENU: {
            return {
                ...state,
                menuPsExpanded: action.value
            }
        }
        case TOGGLE_PORTFOLIO_MENU: {
            return {
                ...state,
                menuPortfolioExpanded: action.value
            }
        }
        case TOGGLE_OVERLAY: {
            return {
                ...state,
                displayOverlay: action.value
            }
        }
        case SET_CALCULATED_SCROLL: {
            return {
                ...state,
                calculatedScroll: action.value
            }
        }
        case SET_PAGE_HEIGHT: {
            return {
                ...state,
                pageHeight: action.value
            }
        }
        case SET_NAVIGATION_ANCHORS: {
            return {
                ...state,
                navigationAnchors: action.anchors
            }
        }
        case RESET_PAGE_SCROLL: {
            return {
                ...defaultState
            }
        }
        default:
            return state;
    }
};

export default page;