import React from 'react';

const Pdf = ({mainColor}) => {
    return (
        <svg version="1.1" id="Pdf" xmlns="http://www.w3.org/2000/svg" width="68px" height="84px" viewBox="56.874 90 498.251 611.999">
        <g>
            <path  fill={mainColor} d="M180.277,483.742c0-10.164-7.052-16.225-19.495-16.225c-5.082,0-8.522,0.501-10.322,0.98v32.609
                c2.131,0.48,4.751,0.64,8.362,0.64C172.084,501.747,180.277,495.047,180.277,483.742z"/>
            <path  fill={mainColor} d="M257.307,467.858c-5.572,0-9.174,0.481-11.304,0.98v72.22c2.132,0.501,5.572,0.501,8.684,0.501
                c22.605,0.159,37.35-12.284,37.35-38.65C292.196,479.98,278.761,467.858,257.307,467.858z"/>
            <path  fill={mainColor} d="M400.423,90H154.619c-35.909,0-65.136,29.247-65.136,65.136v240.862h-6.362c-14.493,0-26.247,11.743-26.247,26.249v159.181
                c0,14.505,11.753,26.246,26.247,26.246h6.362v29.188c0,35.929,29.227,65.137,65.136,65.137h335.389
                c35.888,0,65.117-29.209,65.117-65.137V244.16L400.423,90z M125.732,450.974c7.692-1.3,18.505-2.279,33.738-2.279
                c15.395,0,26.366,2.938,33.739,8.841c7.042,5.563,11.794,14.743,11.794,25.548c0,10.802-3.601,19.986-10.153,26.207
                c-8.522,8.022-21.125,11.623-35.869,11.623c-3.281,0-6.222-0.162-8.521-0.48v39.471h-24.728V450.974z M490.008,662.108H154.619
                c-13.904,0-25.227-11.322-25.227-25.246v-29.188h312.651c14.495,0,26.248-11.741,26.248-26.246V422.247
                c0-14.506-11.753-26.249-26.248-26.249H129.393V155.136c0-13.884,11.324-25.207,25.227-25.207l230.881-0.241v85.342
                c0,24.927,20.226,45.172,45.172,45.172l83.602-0.24l0.94,376.899C515.214,650.786,503.911,662.108,490.008,662.108z
                 M220.948,559.403v-108.43c9.171-1.46,21.125-2.279,33.739-2.279c20.965,0,34.56,3.761,45.211,11.782
                c11.464,8.521,18.665,22.106,18.665,41.61c0,21.125-7.692,35.71-18.346,44.711c-11.623,9.662-29.316,14.244-50.933,14.244
                C236.34,561.042,227.169,560.223,220.948,559.403z M399.705,495.207v20.304h-39.642v44.393h-25.057v-110.39h67.478v20.465h-42.421
                v25.229H399.705z"/>
        </g>
        </svg>
    );
};

export default Pdf;