import { SET_SELECTED_YEAR } from "../actions/portfolioFilterActions";

const defaultState = {
    selectedYear: '2015'
};

const portfolio = (state = defaultState, action = undefined) => {
    const {type} = action;
    switch (type) {
        case SET_SELECTED_YEAR: {
            return {
                ...state,
                selectedYear: action.year
            }
        }
        default:
            return state;
    }
};

export default portfolio;