import React, {Component,Fragment} from 'react';
import {connect} from "react-redux";
import axios from "axios";

// actions
import { toggleOverlay } from "../../../actions/pageActions";
import {togglePlayer} from "../../../actions/playerActions";

// svg
import ScrollerLabel from "../../svg/ScrollerLabel";
import PlayerBack from '../../svg/PlayerBack';

// components
import MenuPortfolioTrigger from '../../Menu/MenuTopPortfolio/MenuPortfolioTrigger';
import MenuTopPortfolio from '../../Menu/MenuTopPortfolio';
import Player from '../../Player';
import PlayerIOS from '../../PlayerIOS';
import Portfolio from "../../Portfolio";

// tools
import {isIOS} from "react-device-detect";

//config
import config from "../../../config";
import {InView} from "react-intersection-observer";
import {getDataById} from"../../../tools/getDataById";


class PortfolioFull extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu:[],
            portfolio:{},
            portfolioList:[],
            languages: [
                {
                    name: "PL",
                    code: "pl",
                    url:"https://dynamofilm.pl",
                    active: true
                },
                {
                    name: "EN",
                    code: "en",
                    url:"http://en.dynamofilm.pl",
                    active: false
                }
            ],
        };
        this.handleOverlay = this.handleOverlay.bind(this);
    }

    handleOverlay() {
        this.props.showOverlay(false);
    }

    componentDidMount() {
        const url = config.domainRelPath;
        axios
            .get(
                url+'/wp-json/api/portfoliomenu/?per_page=30'
            )
            .then(res => {
                this.setState({
                    menu: res.data
                });

            })
            .catch(error => console.log(error));


        axios
            .get(
                url+'/wp-json/acf/v3/pages/?per_page=30'
            )
            .then(res => {
                this.setState({
                    portfolio: getDataById(res.data, 38),
                    portfolioList: getDataById(res.data, 38).portfolioProdukcje
                });
                this.handleOverlay();
            })
            .catch(error => console.log(error));

        window.scrollTo(0,0)
    }

    render() {
        const { playerOpened, openPlayer} = this.props;
        return (
            <Fragment>
                <MenuTopPortfolio menu={this.state.menu}
                         mobilemenu={this.state.menu}
                         languages={this.state.languages} />
                { playerOpened && (
                    <Fragment>

                        {isIOS ?
                            <PlayerIOS video={ this.props.selectedVideo } /> :
                            <Player video={ this.props.selectedVideo } />
                        }
                        <nav>
                            <ul>
                                <li className="home">
                                    <a href={config.domain} target="_top">
                                        <ScrollerLabel mainColor="#E4E2E0" />
                                    </a>
                                </li>
                                <li className="close-player" onClick={openPlayer}>
                                    <PlayerBack mainColor="#E4E2E0" />
                                </li>
                            </ul>
                        </nav>
                    </Fragment>
                )
                }

                <article className="content">
                    <InView>
                        {({ inView, ref }) => (
                            <div  ref={ref}
                                  className="menu-trigger">
                                <MenuPortfolioTrigger expanded={inView} />
                            </div>
                        )}
                    </InView>
                    <Portfolio
                        portfolio={this.state.portfolio}
                        portfolioList={this.state.portfolioList}
                        showAll={true}
                        showFilter={false}
                        theme="tiles"/>
                </article>

            </Fragment>

        );
    }
}

const mapStateToProps =(state) => ({
    translate: state.page.calculatedScroll,
    playerOpened: state.player.playerOpened,
    selectedVideo: state.player.selectedVideo
});

const mapDispatchToProps = dispatch => ({
    openPlayer: () => dispatch(togglePlayer()),
    showOverlay: value => dispatch(toggleOverlay(value))
});

export default connect(mapStateToProps,mapDispatchToProps)(PortfolioFull);