import React from 'react';
import {connect} from "react-redux";
import MediaQuery from "react-responsive";
import {InView} from "react-intersection-observer";
import Falka from "../../../svg/Falka";

const PsAboutUs = (
	{
		slogan,
		text,
		cover,
		translate,
		id,
		layout
	}) => {

	const {transp,img,txt} = slogan;
	const imgBg = {backgroundImage:'url('+img+')'};
	return (
		<>
			{layout === "dynamoDigital" &&
				<>
					<section
						className="section o-nas dds"
						id={id.desktop}
						data-id-mobile={id.mobile}
					>
						<div className="o-nas-fluid
                            container-fluid
                            page-content_wrapper">
							<div className="row start-xs">
								<div className="col col-xs-offset-1 col-xs-10 col-md-offset-2 col-xxl-5">
									<div className="container-fluid introduction_wrapper">
										<div className="row">
											<figure style={imgBg} title={slogan}>
												<img src={transp} alt=""/>
											</figure>
										</div>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col col-xs-offset-1 col-sm-offset-1 col-xs-10 col-sm-5">
									<div className=" container-fluid
                             page-text-content
                             position-relative">
										<div className="title-list">
											<p className="overtitle">
												{txt && <span className="bold">{txt}&nbsp;</span>}
												<span dangerouslySetInnerHTML={{__html: text}}/>
											</p>
										</div>
									</div>
								</div>
							</div>


						</div>
						<figure id="texture-1"
						        className="image-opener
                                page-block
                                texture-1">
							<div className="mask">
								<div className="js-animate"
								     style={{
									     backgroundImage: 'url(' + cover + ')',
									     transform: 'translate3d(0,' + (-30 + (translate * .3)) + 'px,0)'
								     }}>&nbsp;</div>
							</div>
						</figure>
					</section>

					<MediaQuery minWidth={769}>
						<div className="separator">
							<InView>
								{({inView, ref}) => (
									<div ref={ref}
									     className={inView ? 'falka-animate-backwards rotate' : 'falka-animate-backwards'}>
										<Falka mainColor="#000000"/>
									</div>
								)}
							</InView>
						</div>
					</MediaQuery>
				</>
			}
			{layout === "dynamoPost" &&
				<section
					className="section o-nas"
					id={id.desktop}
					data-id-mobile={id.mobile}>
					<div className="page-content_wrapper">
						<div className="row">
							<div className="col col-md-offset-2 col-xs-12 col-md-4">
								<h2
									style={{color:"#000000"}}
									dangerouslySetInnerHTML={{__html: txt}}
								/>
							</div>
							<div className="col col-md-offset-3 col-xs-12 col-md-5 o-nas__text">
								<p dangerouslySetInnerHTML={{__html:text}}/>
							</div>
						</div>
					</div>
				</section>
			}
		</>
	);
};

const mapStateToProps = state => ({
	translate: state.page.calculatedScroll
});

export default connect(mapStateToProps,null)(PsAboutUs);