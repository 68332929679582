import React from 'react';

const Arrow = ({mainColor}) => {
    return (
        <svg version="1.1" id="arrow" xmlns="http://www.w3.org/2000/svg" width="20.25px" height="34.617px" viewBox="0 0 20.25 34.617" >
            <polygon fill={mainColor} points="0,0 20.25,17.309 0,34.617 "/>
        </svg>
    );
};

export default Arrow;