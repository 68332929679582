import React from 'react';

const SocialInstagram = ({mainColor}) => {
    return (
        <svg version="1.1" id="social_insta" xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="288.5 378.5 35 35">
        <title>instagram</title>
            <circle  fill={mainColor} cx="306.434" cy="395.919" r="7.101"/>
            <path  fill={mainColor} d="M317.365,387.061c-1.426,0.02-2.597-1.121-2.616-2.547c-0.02-1.426,1.121-2.597,2.547-2.617
	c1.426-0.019,2.598,1.121,2.616,2.546c0.001,0.012,0.001,0.023,0.001,0.035c0.019,1.407-1.105,2.563-2.513,2.583
	C317.388,387.061,317.376,387.061,317.365,387.061z M305.955,406.879c-6.056-0.242-10.769-5.346-10.527-11.403
	c0.228-5.717,4.81-10.299,10.527-10.527c6.086,0,11.045,4.887,11.13,10.973c-0.028,6.081-4.979,10.987-11.06,10.958
	C306.002,406.879,305.979,406.879,305.955,406.879z M314.782,378.512h-17.725c-2.313-0.103-4.574,0.705-6.298,2.25
	c-1.545,1.724-2.354,3.985-2.25,6.298v17.725c-0.094,2.391,0.78,4.719,2.425,6.455c1.736,1.525,3.989,2.33,6.298,2.25h17.55
	c2.313,0.104,4.574-0.705,6.298-2.25c1.659-1.664,2.534-3.953,2.408-6.299v-17.723c0.072-2.311-0.732-4.56-2.251-6.3
	C319.508,379.265,317.174,378.395,314.782,378.512z"/>
    </svg>
    );
};

export default SocialInstagram;