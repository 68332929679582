import React from 'react';

const SocialFacebook = ({mainColor}) => {
    return (
        <svg version="1.1" id="social_fb" xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="288.5 378.5 35 35">
            <title>fb logo</title>
            <path  fill={mainColor} d="M308.901,386.485c0-1.349,0.658-1.898,1.271-1.898h4.126V378.5h-5.585c-5.946,0-7.389,4.785-7.389,7.64v2.699h-3.483v6.824
	h3.483V413.5h7.64v-17.837h4.706l0.22-2.667l0.424-4.157h-5.412V386.485z"/>
    </svg>
    );
};

export default SocialFacebook;