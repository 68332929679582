import React,{ Component } from 'react';

//svg
import PlayerPlay from '../svg/PlayerPlay';
import PlayerPause from '../svg/PlayerPause';

//tools
import Vimeo from '@u-wave/react-vimeo';

class PlayerIOS extends Component {

    constructor(props) {
        super(props);

        this.state = {
            paused: true
        };

        this.handlePlayerPause = this.handlePlayerPause.bind(this);
        this.handlePlayerPlay = this.handlePlayerPlay.bind(this);
    }

    handleOuterPlayerLoaded() {
        setTimeout(()=> {
            this.setState({
                paused: true
            });
        })
    }

    handlePlayerPlay() {
        this.setState({
            paused: false
        });
    }

    handlePlayerPause() {
        this.setState({
            paused: true
        });
    }



    render() {
        const vimeoId = this.props.video.vimeoId;
        const tytulFilmu = this.props.video.tytulFilmu;
        const klient = this.props.video.klient;
        const agencja = this.props.video.agencja;
        const rezyser = this.props.video.rezyser;
        const operator = this.props.video.operator;
        const panorama = this.props.video.panorama;

        const { paused } = this.state;


        return (
            <div className={ panorama ? 'player-container panoramic' : 'player-container' }>

                { paused &&
                    <header>
                        <h4>{tytulFilmu}</h4>
                        <ul>
                            { klient && <li>Klient: {klient}</li> }
                            { agencja && <li>Agencja: {agencja}</li> }
                            { rezyser && <li>Reżyser: {rezyser}</li> }
                            { operator && <li>Operator: {operator}</li> }
                        </ul>
                    </header>
                }

                <nav>
                    <ul>
                        <li className="pause"
                            onClick={this.handlePlayerPause} >
                            <PlayerPause />
                        </li>
                        <li className="play"
                        onClick={this.handlePlayerPlay}>
                            <PlayerPlay />
                        </li>
                    </ul>

                </nav>

                <figure className="stage mobile ios">
                    <Vimeo
                        video={vimeoId}
                        paused={paused}
                        showByline={false}
                        showTitle={false}
                        className="vid"
                        autoplay={false}
                        autopause={true}
                        muted={false}
                    />
                </figure>
            </div>
        );
    }


};

export default PlayerIOS;