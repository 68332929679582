import React, {Fragment, useEffect, useState} from 'react';
import {connect} from "react-redux";
import axios from "axios";

//actions
import {togglePlayer} from "../../../actions/playerActions";
import {toggleOverlay} from "../../../actions/pageActions";

//components
import PageSmoothScroller from '../../controls/PageSmoothScroller';
import MenuTopDp from "../../Menu/MenuTopDp";
import MenuDpTrigger from "../../Menu/MenuTopDp/MenuDpTrigger";
import PlayerIOS from "../../PlayerIOS";
import Player from "../../Player";
import DpPortfolio from "../../PortfolioDp";
import DpKontakt from "../DynamoPost/Kontakt";

//svg
import ScrollerLabel from "../../svg/ScrollerLabel";
import PlayerBack from "../../svg/PlayerBack";

//tools
import {InView} from "react-intersection-observer";
import {isIOS} from "react-device-detect";

//config
import config from "../../../config";
import {getDataById} from"../../../tools/getDataById";

const PortfolioFullDp = ({
                             translate,
                             playerOpened,
                             selectedVideo,
                             openPlayer,
                             showOverlay
                         }) => {

    const [state, setState] = useState({
        commonData: {},
        portfolio: {},
        kontakt:{},
        languages: [
            {
                name: "PL",
                code: "pl",
                url:"https://dynamofilm.pl",
                active: true
            },
            {
                name: "EN",
                code: "en",
                url:"http://en.dynamofilm.pl",
                active: false
            }
        ],
    });

    const [menu,setMenu] = useState([]);

    useEffect( () => {
        const url = config.domainRelPath;
        axios
            .get(
                url+'/wp-json/api/portfoliomenu-dp/?per_page=30'
            )
            .then(res => {
                setMenu(res.data);
            })
            .catch(error => console.log(error));
        axios
            .get(
                url+'/wp-json/acf/v3/pages/?per_page=30'
            )
            .then(res => {
                setState({
                    ...state,
                    kontakt: getDataById(res.data, 859),
                    portfolio: getDataById(res.data, 857),
                    commonData: getDataById(res.data, 855)
                });
                showOverlay(false);
            })
            .catch(error => console.log(error));
        window.scrollTo(0,0)
    },[
        setMenu,
        setState,
        showOverlay
    ]);

    const {
        commonData,
        portfolio,
        kontakt,
        languages
    } = state;
    return (
        <Fragment>
            <MenuTopDp menu={menu}
                       languages={languages} />
            { playerOpened && (
                <Fragment>
                    {isIOS ?
                        <PlayerIOS video={ selectedVideo } /> :
                        <Player video={ selectedVideo } />
                    }
                    <nav>
                        <ul>
                            <li className="home">
                                <a href={config.domain} target="_top">
                                    <ScrollerLabel mainColor="#E4E2E0" />
                                </a>
                            </li>
                            <li className="close-player" onClick={openPlayer}>
                                <PlayerBack mainColor="#E4E2E0" />
                            </li>
                        </ul>
                    </nav>
                </Fragment>
            )
            }
            <PageSmoothScroller>

                <article className="content dp-content"
                         style={{ transform: 'matrix3d(1, 0.000174533, 0, 0, -0.000174533, 1, 0, 0, 0, 0, 1, 0, 0, -'+translate+', 0, 1)' }}>
                    <InView>
                        {({ inView, ref }) => (
                            <div  ref={ref}
                                  className="menu-trigger">
                                <MenuDpTrigger expanded={inView} />
                            </div>
                        )}
                    </InView>


                    <DpPortfolio
                        id={portfolio.id}
                        mobileId={portfolio.idmobile}
                        accentColorRGBA={commonData.accent_color_rgba}
                        clips={portfolio.portfolioProdukcje}
                        locale={{
                            agencja: portfolio.agencja,
                            klient: portfolio.klient,
                            wiecej: portfolio.tekst_linku
                        }}
                        transpBg={portfolio.tlo_transp}
                        transpBgFull={portfolio.tlo_transp_panorama}
                    />

                    <DpKontakt data={kontakt} />

                </article>

            </PageSmoothScroller>

        </Fragment>
    );
};

const mapStateToProps =(state) => ({
    translate: state.page.calculatedScroll,
    playerOpened: state.player.playerOpened,
    selectedVideo: state.player.selectedVideo
});

const mapDispatchToProps = dispatch => ({
    openPlayer: () => dispatch(togglePlayer()),
    showOverlay: value => dispatch(toggleOverlay(value))
});

export default connect(mapStateToProps,mapDispatchToProps)(PortfolioFullDp);