import React from 'react';

const DpAboutUs = (
    {
        id,
        mobileId,
        accentColor,
        title,
        text
}) => {
    return (
        <section
            className="section o-nas"
            id={id}
            data-id-mobile={mobileId}>
            <div className="page-content_wrapper">
                <div className="row">
                    <div className="col col-md-offset-2 col-xs-12 col-md-4">
                        <h2
                            style={{color:accentColor}}
                            dangerouslySetInnerHTML={{__html: title}}
                        />
                    </div>
                    <div className="col col-md-offset-3 col-xs-12 col-md-5 o-nas__text">
                        <p dangerouslySetInnerHTML={{__html:text}}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DpAboutUs;