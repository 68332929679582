import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleDpMenu } from "../../../../actions/pageActions";

class MenuDpTrigger extends Component {
    componentDidUpdate() {
        this.props.toggleMenu(this.props.expanded)
    }
    render (){
        return <div />
    }

}
const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    toggleMenu: value => dispatch( toggleDpMenu(value) )
});
export default connect(mapStateToProps,mapDispatchToProps)(MenuDpTrigger);