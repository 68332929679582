import React from 'react'
import {Route, Switch} from 'react-router';
import Home from '../components/Views/Home';
import PortfolioFull from '../components/Views/PortfolioFull';
import PortfolioFullDds from '../components/Views/PortfolioFullDds';
import PortfolioFullDp from "../components/Views/PortfolioFullDp";
import Dds from '../components/Views/Dds';
import DynamoPost from "../components/Views/DynamoPost";
import ProductionService from "../components/Views/ProductionService";
import config from '../config';

const relPath = config.domainRelPath;

const routes = (
	<Switch>
		<Route exact path={relPath + "/"} component={Home}/>
		<Route path={relPath + "/portfolio"} component={PortfolioFull}/>
		<Route path={relPath + "/portfolio-dds"} component={PortfolioFullDds}/>
		<Route path={relPath + "/portfolio-dp"} component={PortfolioFullDp}/>
		<Route path={relPath + "/dynamo-digital-studio"} component={Dds}/>
		<Route path={relPath + "/dynamo-post"} component={DynamoPost}/>
		<Route path={relPath + "/production-service"} component={ProductionService}/>
	</Switch>
);

export default routes;