import React, {Component} from 'react';
import {connect} from "react-redux";


//tools

import {setCalculatedScroll,setPageHeight} from "../../../../actions/pageActions";

class PageSmoothScroller extends Component {

    componentDidMount() {
        const scroller = {
            target: document.querySelector(".content"),
            ease: 0.05, // <= scroll speed
            endY: 0,
            y: 0,
            resizeRequest: 1,
            scrollRequest: 0,
        };

        let requestId = null;

        const onLoad = () => {
            updateScroller.call(this);
            window.focus();
            window.addEventListener("resize", onResize.bind(this));
            document.addEventListener("scroll", onScroll.bind(this));
        };

        window.addEventListener("load", onLoad.bind(this));

        const updateScroller = () => {
            const pageHeight = document.querySelector('div.App');
            this.props.dispatchPageHeight(pageHeight.getBoundingClientRect().height);

            const html = document.documentElement;
            const body = document.body;

            const resized = scroller.resizeRequest > 0;

            if (resized) {
                const height = scroller.target.clientHeight;
                body.style.height = height + "px";
                scroller.resizeRequest = 0;
            }

            const scrollY = window.pageYOffset || html.scrollTop || body.scrollTop || 0;

            scroller.endY = scrollY;
            scroller.y += (scrollY - scroller.y) * scroller.ease;

            if (Math.abs(scrollY - scroller.y) < 0.05 || resized) {
                scroller.y = scrollY;
                scroller.scrollRequest = 0;
            }

            requestId = scroller.scrollRequest > 0 ? requestAnimationFrame(updateScroller.bind(this)) : null;



            this.props.dispatchCalculatedScroll( scroller.y);


        };

        const onScroll = () => {
            scroller.scrollRequest++;
            if (!requestId) {
                requestId = requestAnimationFrame(updateScroller.bind(this));
            }
        };

        const onResize = () => {
            scroller.resizeRequest++;
            if (!requestId) {
                requestId = requestAnimationFrame(updateScroller.bind(this));
            }
        }
    }

    render() {
        return this.props.children
    }
}

const mapStateToProps = (state) => ({
    calculatedScroll: state.page.calculatedScroll
});

const mapDispatchToProps = dispatch => ({
    dispatchCalculatedScroll: value => dispatch( setCalculatedScroll(value) ),
    dispatchPageHeight: value => dispatch( setPageHeight(value) )
});



export default connect(mapStateToProps,mapDispatchToProps)(PageSmoothScroller);