export const TOGGLE_HOME_MENU = 'menuHomeToggleState';
export const TOGGLE_DDS_MENU = 'menuDdsToggleState';
export const TOGGLE_DP_MENU = 'menuDpToggleState';
export const TOGGLE_PS_MENU = 'menuPsToggleState';
export const TOGGLE_PORTFOLIO_MENU = 'menuPortfolioToggleState';
export const TOGGLE_OVERLAY = 'routesToggleOverlay';
export const SET_CALCULATED_SCROLL = 'parallaxSetCalculatedScroll';
export const SET_PAGE_HEIGHT = 'parallaxSetPageHeight';
export const SET_NAVIGATION_ANCHORS = 'scrollSetNavigationAnchors';
export const RESET_PAGE_SCROLL = 'resetPageScroll';


export const toggleHomeMenu = value => ({
    type: TOGGLE_HOME_MENU,
    value
});
export const toggleDdsMenu = value => ({
    type: TOGGLE_DDS_MENU,
    value
});
export const toggleDpMenu = value => ({
    type: TOGGLE_DP_MENU,
    value
});
export const togglePsMenu = value => ({
    type: TOGGLE_PS_MENU,
    value
});
export const togglePortfolioMenu = value => ({
    type: TOGGLE_PORTFOLIO_MENU,
    value
});

export const resetScroll = () => ({
    type: RESET_PAGE_SCROLL
});

export const toggleOverlay = value => ({
    type: TOGGLE_OVERLAY,
    value
});

export const setCalculatedScroll = value => ({
    type: SET_CALCULATED_SCROLL,
    value
});

export const setPageHeight = value => ({
    type: SET_PAGE_HEIGHT,
    value
});

export const setNavigationAnchors = (anchors) => ({
    type: SET_NAVIGATION_ANCHORS,
    anchors
});
