import React, {Component,Fragment} from "react";
import { connect } from "react-redux";

//actions
import {toggleDdsMenu} from "../../../actions/pageActions";

//tools
import MediaQuery from 'react-responsive';

//svg components
import MenuClose from "../../svg/MenuClose";
import MenuOpen from "../../svg/MenuOpen";
import MenuDivider from "../../svg/MenuDivider";
import LogoDds from "../../svg/LogoDds";
import ScrollerLabel from "../../svg/ScrollerLabel";

//components
import MenuLang from "../MenuLang";
import {Link} from "react-router-dom";

//config
import config from "../../../config";

class MenuTopDds extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hovered: null,
            clickedItem: null,
            mobileMenuClosed: true
        };
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleMouseClick = this.handleMouseClick.bind(this);
        this.handleMenuClose = this.handleMenuClose.bind(this);
        this.handleMenuOpen = this.handleMenuOpen.bind(this);
        this.handleMenuHash = this.handleMenuHash.bind(this);
        this.handleMenuHashMobile = this.handleMenuHashMobile.bind(this);
    }

    handleMenuClose(){
        this.setState(state => ({
            mobileMenuClosed: true
        }));
    }

    handleMenuOpen(){
        this.setState(state => ({
            mobileMenuClosed: false
        }));
    }

    handleMouseLeave(){
        this.setState(state => ({
            hovered: null
        }));
    }

    handleMouseClick(){
        this.setState(state => ({
            hovered: null
        }));
    }

    handleMenuHash(id){
        const target = document.getElementById('item-'+id);
        const posY = target.offsetTop;
        //console.log('handle hash: item-' + id +', position Y: ' + posY);
        window.scrollTo(0,posY - ( posY * 0.5 ))
    }
    handleMenuHashMobile(id){
        const target = document.querySelector('*[data-id-mobile="item-' + id + '"]' );
        const posY = target.offsetTop;
        //console.log('handle hash: section[data-id-mobile="item-' + id + '", position Y: ' + posY);
        window.scrollTo(0,posY - ( posY * 0.5 ))
    }

    render() {
        return (
            <header className={ (!this.state.mobileMenuClosed) ? 'header opened':'header' }>

                <figure className="desktop inline-block">
                    <div className="logo-mask">
                        <LogoDds/>
                    </div>
                </figure>
                <figure className="mobile inline-block"><ScrollerLabel/></figure>
                {this.state.mobileMenuClosed && (
                    <div className="menu-open mobile"
                         onClick={this.handleMenuOpen}>
                    <MenuOpen/>
                </div>
                )}
                {!this.state.mobileMenuClosed && (
                <div className="menu-close mobile"
                     onClick={this.handleMenuClose}>
                    <MenuClose/>
                </div>
                )}

                <nav className="menu text-ar"
                     data-menu-expanded={this.props.menuDdsExpanded ? 'expanded' : 'collapsed'}>
                    {/* change 2nd class to collapse to restore menu hide on scroll*/}

                    {/*mobile*/}
                    <MediaQuery maxWidth={768}>
                        <ul className="mobile">
                            {this.props.menu
                                .map(item =>
                                    <Fragment key={item.ID}>
                                        <li
                                            data-id={item.object_id}
                                            data-page-id={item.ID}
                                            className={ (item.ID === this.state.hovered) ? 'active':'' }
                                            onMouseEnter={ () => this.setState({ hovered: item.ID }) }>
                                            {( item.type === 'custom' ) ?
                                                <Link to={ config.domainRelPath + item.url }>{item.title}</Link>
                                                :
                                                <span onClick={ () => this.handleMenuHashMobile(item.ID) } >{item.title}</span>}
                                        </li>
                                        {(item.object_id === 40) && <li className="divider" key={999}><MenuDivider /></li>}
                                    </Fragment>
                                )}

                        </ul>
                    </MediaQuery>

                    <MenuLang languages={this.props.languages} />

                    <MediaQuery minWidth={769}>
                    {/*desktop*/}
                        <ul className="desktop">
                            {this.props.menu
                                .map(item =>
                                    <li
                                        key={item.ID}
                                        data-id={item.object_id}
                                        data-page-id={item.ID}
                                        className={ (item.ID === this.state.hovered) ? 'active':'' }
                                        onMouseEnter={ () => this.setState({ hovered: item.ID }) }>

                                        {( item.type === 'custom' ) ?
                                            <Link to={ config.domainRelPath + item.url }>{item.title}</Link>
                                            :
                                            <span onClick={ () => this.handleMenuHash(item.ID) } >{item.title}</span>}
                                    </li>
                                )}
                        </ul>
                        <div className={"burger"}
                             onClick={ () =>this.props.toggleMenu(true)}>
                            <MenuOpen/>
                        </div>
                    </MediaQuery>
                </nav>
            </header>
        )
    }
}
const mapStateToProps = (state) => ({
    menuDdsExpanded: state.page.menuDdsExpanded
});
const mapDispatchToProps = dispatch => ({
    toggleMenu: value => dispatch( toggleDdsMenu(value) )
});


export default connect(mapStateToProps,mapDispatchToProps)(MenuTopDds);