import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleDdsMenu } from "../../../../actions/pageActions";

class MenuDdsTrigger extends Component {
    componentDidUpdate() {
        this.props.toggleMenu(this.props.expanded)
    }
    render (){
        return <div />
    }

}
const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    toggleMenu: value => dispatch( toggleDdsMenu(value) )
});
export default connect(mapStateToProps,mapDispatchToProps)(MenuDdsTrigger);