import React from 'react';

const PlayerBack = ({mainColor}) => {
    return (
        <svg version="1.1"width="13px" height="23px" id="player_pause" xmlns="http://www.w3.org/2000/svg" viewBox="265.581 372.945 20 36.588">
            <polygon fill={mainColor} points="283.644,409.533 265.581,391.198 283.834,372.945 285.581,374.692 269.067,391.214 285.4,407.803"/>
        </svg>
    );
};

export default PlayerBack;