import React from 'react';

const PlayerPlay = () => {
    return (
        <svg version="1.1" width="10.125px" height="14.906px" id="player_play" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.125 14.906">
            <polygon fill="#E4E2E0" points="0,0 0,14.906 10.125,7.454 0.001,0 "/>
        </svg>
    );
};

export default PlayerPlay;