import React,{ useState,useEffect, useCallback } from 'react';
import {connect} from "react-redux";

//components
import ScrollerDp from "../ScrollerDp";

//tools
import MediaQuery from 'react-responsive';
import classnames from "classnames";
import {selectedVideo, togglePlayer} from "../../actions/playerActions";
import {selectedYear} from "../../actions/portfolioFilterActions";

//svg
import PlayDp from "../svg/PlayDP";
import {InView} from "react-intersection-observer";
import {Link} from "react-router-dom";
import config from "../../config";
import ScrollerLabel from "../svg/ScrollerLabel";

const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));


const DpPortfolio = (
    {
        id,
        mobileId,
        accentColorRGBA,
        clips,
        locale,
        openPlayer,
        selectVideo,
        transpBg,
        transpBgFull,
        home,
        picsOnly=false,
        lightMode=false,
        disableInnerScroller=false,
        showTitles=true,
    }
) => {

    const [ itemHovered, setItemHovered ] = useState(null);

    const [ clipsList, setClipsList ] = useState(clips);

    const [ dimensions, setDimensions ] = useState({height:0});

    const portfolioContainer = useCallback(domNode => {
        setTimeout(()=> {
            if (domNode) {
                setDimensions(domNode.getBoundingClientRect());
            }
        },1000)
        },[setDimensions]);

    useEffect( () => {
        if(home) {
            const filterList = clips && clips.filter(clip => clip.na_strone_glowna || clip.is_reel);
            setClipsList(filterList)
        } else {
            const filterList = clips && clips.filter(clip => !clip.is_reel);
            setClipsList(filterList)
        }
    },[home,clips,setClipsList]);

    return (
        <InView>
            {({ inView, ref }) => (
                <section
                    className="section portfolio"
                    id={id}
                    data-id-mobile={mobileId}
                    ref={portfolioContainer}
                    style={{
                        backgroundColor: lightMode ? "#FFFFFF" : "#000000"
                    }}
                >

                    <div className="page-content_wrapper">
                        <div className="row">
                            <div className={classnames(
                                "col portfolio__scroller-holder",{
                                    "col-xs-12 col-sm-1": !disableInnerScroller,
                                    "col-xs-12": disableInnerScroller,
                                }
                            )}>
                                <MediaQuery minWidth={769}>
                                    {!disableInnerScroller &&
                                        <ScrollerDp
                                            light={!lightMode}
                                            containerHeight={dimensions.height}
                                        />
                                    }
                                </MediaQuery>
                            </div>
                            <div className={classnames(
                                "col",{
                                    "col-xs-12 col-md-11": !disableInnerScroller,
                                    "col-xs-12": disableInnerScroller,
                                }
                            )} >
                                <div className="row" ref={ref}>
                                {
                                    clipsList && clipsList
                                        .map((item,index)=>
                                        <div
                                            key={index}
                                            className={classnames(
                                                "col col-xs-12 portfolio__item",{
                                                    "col-sm-4": !item.is_full_width && !item.is_reel,
                                                    "portfolio__item__full-width": item.is_full_width,
                                                    "portfolio__item__reel": item.is_reel,
                                                    "portfolio__item__overview-clip": item.short_clip,
                                                    "portfolio__item__hovered": index===itemHovered,
                                                    "portfolio__item__grey": picsOnly,
                                                    "portfolio__item__grey__applyEffect": index===itemHovered && picsOnly,
                                                }
                                            )}
                                            style={{backgroundImage:`url(${item.okladka})`}}
                                            onMouseEnter={ () => setItemHovered(index) }
                                            onMouseLeave={ () => setItemHovered(null) }
                                            onClick={e=>{
                                                if(!picsOnly) {
                                                    openPlayer();
                                                    selectVideo({
                                                        vimeoId:item.vimeo_id,
                                                        tytulFilmu:item.tytul_filmu,
                                                        podtytulFilmu:item.podtytul_fimu,
                                                        klient:item.klient,
                                                        agencja:item.agencja,
                                                        rezyser:item.rezyser,
                                                        operator:item.operator,
                                                        panorama: item.panorama
                                                    });
                                                } else {
                                                    e.preventDefault();
                                                }

                                            }}
                                        >
                                            {
                                                ( item.is_reel || index===itemHovered ) && !picsOnly &&
                                                    <figure className={index===itemHovered ? "animate" : ""}>
                                                        <PlayDp mainColor="#FFFFFF"/>
                                                    </figure>
                                            }
                                            {
                                                (
                                                    item.is_reel ||
                                                    index===itemHovered ||
                                                    isMobile
                                                ) && !picsOnly && showTitles &&
                                                <figcaption className={classnames(
                                                    {
                                                        "animate": index===itemHovered && !item.is_reel,
                                                        "animate-reel-title" : item.is_reel && inView
                                                    }
                                                )}>
                                                    <header>{item.is_reel ? "" : locale.klient}</header>
                                                    <article>
                                                        <h3>{item.tytul_filmu}</h3>
                                                        {/*<p>{item.podtytul_fimu}</p>*/}
                                                    </article>
                                                </figcaption>
                                            }
                                            {
                                                !item.is_reel &&
                                                index===itemHovered &&
                                                !picsOnly && showTitles &&
                                                    <div
                                                        className="mask"
                                                        style={{backgroundColor:accentColorRGBA}}
                                                    />
                                            }
                                            {
                                                picsOnly && showTitles &&
                                                <figcaption className={classnames(
                                                    {
                                                        "lightmode": lightMode,
                                                        "animate": index===itemHovered && !item.is_reel,
                                                        "animate-reel-title" : item.is_reel && inView
                                                    }
                                                )}>
                                                    <header>{item.tytul_filmu}</header>
                                                    <article>
                                                        <h3>{item.podtytul_fimu}</h3>
                                                    </article>
                                                </figcaption>
                                            }
                                            {
                                                item.podglad_video && !picsOnly ?
                                                    <video
                                                        width="720"
                                                        height="576"
                                                        muted
                                                        playsInline
                                                        onMouseEnter={e => { e.target.play() } }
                                                        onMouseLeave={e => {
                                                            e.target.pause();
                                                            e.target.currentTime = 0
                                                        }}
                                                        src={item.short_clip}
                                                    >
                                                        <source src={item.short_clip} type="video/mp4"/>
                                                    </video>
                                                    :
                                                    <img
                                                        src={item.is_full_width || item.is_reel ? transpBgFull : transpBg}
                                                        alt={`${item.tytul_filmu} | ${item.podtytul_fimu}`}
                                                    />
                                            }

                                        </div>
                                    )
                                }
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        home &&
                        <div className="more">
                            <Link to={config.domainRelPath + `/portfolio-dp`}>
                                <figure><ScrollerLabel mainColor="#FFFFFF"/></figure>
                                <p style={{color:"#FFFFFF"}}>{locale.wiecej}</p>
                            </Link>
                        </div>
                    }

                </section>

            )}
        </InView>
    );
};

const mapStateToProps = (state) => ({
    playerOpened: state.player.playerOpened,
    selectedVideo: state.player.selectedVideo,
    selectedYear: state.portfolio.selectedYear,
    translate: state.page.calculatedScroll
});

const mapDispatchToProps = dispatch => ({
    openPlayer: () => dispatch(togglePlayer()),
    selectVideo: (id,title,subtitle,klient,agencja,rezyser,operat,panorama) => dispatch(selectedVideo(id,title,subtitle,klient,agencja,rezyser,operat,panorama)),
    setYear: year => dispatch(selectedYear(year))
});

export default connect(mapStateToProps,mapDispatchToProps)(DpPortfolio);