import React,{ Component, Fragment } from 'react';
import {connect} from "react-redux";
import { Link } from 'react-router-dom';

//actions
import { togglePlayer, selectedVideo } from "../../actions/playerActions";
import { selectedYear } from "../../actions/portfolioFilterActions";

//components
import AnimatedPortfolioItem from './AnimatedPortfolioItem';
import AnimatedPortfolioTextItem from './AnimatedPortfolioTextItem';

//svg components
import ScrollerLabel from "../svg/ScrollerLabel";

//config
import config from "../../config";

class Portfolio extends Component {

    constructor(props) {
        super(props);
        this.handlePlayer = this.handlePlayer.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
    }

    handlePlayer(id,title,subtitle,klient,agencja,rezyser,operat,panorama) {
        this.props.openPlayer();
        this.props.selectVideo({
            vimeoId:id,
            tytulFilmu:title,
            podtytulFilmu:subtitle,
            klient:klient,
            agencja:agencja,
            rezyser:rezyser,
            operator:operat,
            panorama: panorama
        });
    }

    handleFilter(item) {
        this.props.setYear(item);
    }

    render() {

        const prace = this.props.portfolio;

        const yearList = [...new Set(this.props.portfolioList.map(a => a.rok))];

        const linkSuffix = () => {
           if ( this.props.suffix ) {
               return '-' + this.props.suffix;
           } else {
;               return ''
           }
        }

        const PortfolioList = ({displayYear}) => {
            return(
                    <ul className={this.props.theme==='tiles'?"row":""}>
                        {this.props.portfolioList
                            .filter(item =>{
                                if (this.props.showAll) {
                                    // enable to apply filter
                                    // return item.rok === displayYear
                                    return !item.na_strone_glowna
                                } else {
                                    return item.na_strone_glowna
                                }
                            })
                            .map((item,index) =>
                                <li key={item.vimeo_id}
                                    className={this.props.theme==='tiles'?"col col-md-4":""}
                                    onClick={ () => this.handlePlayer(
                                        item.vimeo_id,
                                        item.tytul_filmu,
                                        item.podtytul_filmu,
                                        item.klient,
                                        item.agencja,
                                        item.rezyser,
                                        item.operator,
                                        item.panorama
                                    ) } >

                                    <div className="portfolio-content-wrapper">


                                        {this.props.theme === 'tiles' ?
                                            <figure style={{backgroundImage: 'url(' + item.okladka + ')'}}>
                                                <img src={prace.tlo_transp}
                                                     alt={item.tytul_filmu}/>
                                            </figure> :
                                            <AnimatedPortfolioItem src={item.okladka}
                                                                   alt={item.tytul_filmu}
                                                                   index={index}/>
                                        }

                                        {this.props.theme === 'tiles' ?
                                            <figcaption>
                                                <div className="row bottom-md">
                                                    <div className="col col-md-6">
                                                        <header>
                                                            <h3>{item.tytul_filmu}</h3>
                                                            <h4>{item.podtytul_fimu} / {item.rok}</h4>
                                                        </header>
                                                    </div>
                                                    {/*<div className="col col-md-6">
                                                        <p>{prace.klient}: {item.klient}</p>
                                                        <p>{prace.agencja}: {item.agencja}</p>
                                                        <p>{prace.rezyser}: {item.rezyser}</p>
                                                        <p>{prace.operator}: {item.operator}</p>
                                                    </div>*/}
                                                </div>
                                            </figcaption> :

                                            <AnimatedPortfolioTextItem title={item.tytul_filmu}
                                                                       subtitle={item.podtytul_fimu}
                                                                       year={item.rok}
                                                                       labelClient={prace.klient}
                                                                       labelAgency={prace.agencja}
                                                                       labelDirector={prace.rezyser}
                                                                       labelOperator={prace.operator}
                                                                       labelPostprod={prace.postprodukcja}
                                                                       labelMusic={prace.muzyka}
                                                                       itemClient={item.klient}
                                                                       itemAgency={item.agencja}
                                                                       itemDirector={item.rezyser}
                                                                       itemOperator={item.operator}
                                                                       itemPostprod={item.postprodukcja}
                                                                       itemlMusic={item.muzyka}
                                                                       index={index}/>
                                        }

                                    </div>
                                </li>
                            )}
                    </ul>
                )
        }

        return (
            <Fragment>
                    <section className={`section portfolio ` + this.props.theme}
                             id={prace.id}
                             data-id-mobile={prace.idmobile}>
                        <header>{prace.tytul}</header>
                        <article>
                        {this.props.showFilter &&
                        <ul className="years">
                            {yearList
                                .sort()
                                .map((item,index) =>
                                <li key={index}
                                    className={item===this.props.selectedYear ? "active":""}
                                    onClick={() => this.handleFilter(item)}>
                                    {item}
                                </li>
                            )}
                        </ul>
                        }
                        <nav>
                            <PortfolioList displayYear={this.props.selectedYear} />
                        </nav>

                        {!this.props.showAll &&
                            <div className="more">
                                <Link to={config.domainRelPath + `/portfolio` + linkSuffix()}>
                                    <figure><ScrollerLabel/></figure>
                                    <p>{prace.tekst_linku}</p>
                                </Link>
                            </div>
                        }
                        </article>

                    </section>

            </Fragment>
        )
    }
};



const mapStateToProps = (state) => ({
    playerOpened: state.player.playerOpened,
    selectedVideo: state.player.selectedVideo,
    selectedYear: state.portfolio.selectedYear
});

const mapDispatchToProps = dispatch => ({
    openPlayer: () => dispatch(togglePlayer()),
    selectVideo: (id,title,subtitle,klient,agencja,rezyser,operat,panorama) => dispatch(selectedVideo(id,title,subtitle,klient,agencja,rezyser,operat,panorama)),
    setYear: year => dispatch(selectedYear(year))
});

export default connect(mapStateToProps,mapDispatchToProps)(Portfolio);