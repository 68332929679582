import React from 'react';

const MenuDivider= () => {
    return (
        <svg version="1.1" id="menu_divider" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="109.417px" height="109.333px" viewBox="247.25 341.333 109.417 109.333" >
        <line fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" x1="247.954" y1="450" x2="355.953" y2="342"/>
        </svg>
    );
};

export default MenuDivider;