import React from 'react';

const PlayerPause = () => {
    return (
        <svg version="1.1" width="10.125px" height="12.947px" id="player_pause" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.125 12.947">
            <rect y="0" fill="#E4E2E0" width="3.938" height="12.947"/>
            <rect x="6.187" y="0" fill="#E4E2E0" width="3.938" height="12.947"/>
        </svg>
    );
};
export default PlayerPause;