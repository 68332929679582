import React from 'react';

const SocialVimeo = ({mainColor}) => {
    return (
        <svg version="1.1" id="social_vimeo" xmlns="http://www.w3.org/2000/svg" width="35px" height="35px" viewBox="288.48 378.528 35 35">
        <title>vimeo</title>
    <path  fill={mainColor} d="M308.559,389.013c4.053-2.418,6.104,0.917,4.053,4.77c-2.052,3.853-3.919,6.287-4.87,6.287c-0.95,0-1.667-2.535-2.852-6.987
	c-1.184-4.453-1.134-12.925-5.903-12.007c-4.77,0.917-10.506,8.005-10.506,8.005l1.434,1.868c0,0,2.935-2.318,3.886-1.184
	c0.951,1.134,4.703,15.176,5.954,17.644c1.084,2.268,4.119,5.27,7.354,3.135c3.235-2.134,14.209-11.456,16.193-22.446
	S310.159,379.407,308.559,389.013z"/>
    </svg>
    );
};

export default SocialVimeo;