import React,{Component} from 'react';
import Wave from "../../../svg/Wave";
import Arrow from "../../../svg/Arrow";
import { InView } from 'react-intersection-observer';
import MediaQuery from 'react-responsive';
import Slider from "react-slick";


class Zespol extends Component {

    constructor(props,context) {
        super(props, context);

        this.state = {
            showItems: 3,
            toggleMore:true,
            listHeight: ''
        };

        this.handleShowMore = this.handleShowMore.bind(this);
        this.handleShowLess = this.handleShowLess.bind(this)
    }

    handleShowMore() {
        this.setState({
            showItems: 100,
            toggleMore:false
        })
    }
    handleShowLess() {
        this.setState({
            showItems: 3,
            toggleMore:true
        })
    }

    render(){
        const team = this.props.zespol,
            title = team.tytul,
            id = team.id,
            opis = team.opis,
            teamCoverDesktop = team.teamCoverDesktop,
            teamCoverMobile=team.teamCoveMobile,
            wiecej = team.wiecej,
            mniej = team.mniej;

        const members = this.props.zespolLista;
        const opisHtml = {__html: opis};


        const settings = {
            dots: false,
            arrows: true,
            infinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            nextArrow: <div className="arrow next"><Arrow /></div>,
            prevArrow: <div className="arrow prev"><Arrow /></div>
        };

        return (
            <section className="section zespol" id={id}>
                <header>{title}</header>
                <article>
                    <section className="top">
                        <InView>
                            {({ inView, ref }) => (
                            <p className={inView ? 'slide-in-left':''}
                               ref={ref}
                               dangerouslySetInnerHTML={opisHtml} />
                            )}
                        </InView>
                        <InView>
                            {({ inView, ref }) => (
                            <figure className={inView ? 'slide-in-right':''}
                                    ref={ref}>
                                <img src={teamCoverDesktop}
                                     alt={"Dynamo team"}
                                     className="desktop"/>
                                <img src={teamCoverMobile}
                                     alt="Dynamo team"
                                     className="mobile"/>
                            </figure>
                            )}
                        </InView>
                    </section>
                    <section className="row btm middle-xs top-md">
                        <figure className="col-xs-6
                                       col-md-3
                                       center-xs
                                       ">
                            <InView>
                            {({ inView, ref }) => (
                            <div  ref={ref}
                                  className={
                                      inView ?
                                          'box wave falka-animate rotate'
                                          :
                                          'box wave falka-animate'}>
                                <Wave mainColor="#000000" />
                            </div>
                            )}
                            </InView>
                        </figure>

                        {/*mobile*/}
                        <MediaQuery maxWidth={768}>
                            <ul expanded="true"
                                className="team-members
                                        col-xs-6
                                        col-md-9">
                                {members
                                    .slice(0, this.state.showItems)
                                    .map((item,index) =>
                                        <li key={index}>
                                            <p>
                                                {item.name}<br/>
                                                <span>{item.stanowisko}</span>

                                            </p>
                                        </li>
                                    )}
                                <li className="switch"
                                    key={999}>
                                    {this.state.toggleMore ?
                                        <p onClick={this.handleShowMore}>{wiecej}</p>
                                        :
                                        <p onClick={this.handleShowLess}>{mniej}</p>}

                                </li>
                            </ul>
                        </MediaQuery>


                        {/*desktop*/}
                        <MediaQuery minWidth={769}>
                        <div className="team-members
                                        col-xs-6
                                        col-md-9">
                            <Slider {...settings}>
                            {members
                                .map((item,index) =>
                                <div key={index}>
                                    <p>
                                        {item.name}<br/>
                                        <span>{item.stanowisko}</span>

                                    </p>
                                </div>
                            )}
                            </Slider>
                        </div>
                        </MediaQuery>


                    </section>


                </article>

            </section>
        );

    }



};

export default Zespol;