import React, {Fragment} from 'react';
import {connect} from "react-redux";

//svg
import PlayerBack from "../../../svg/PlayerBack";
import Falka from "../../../svg/Falka";

// tools
import MediaQuery from 'react-responsive';
import Slider from "react-slick";
import {InView} from "react-intersection-observer";
import classnames from "classnames";


const Studio = props => {

    const studio = props.studio,
        title = studio.tytul,
        id = studio.id,
        idmobile = studio.idmobile,
        slogan = studio.slogan,
        slogantransp = studio.slogantranp,
        obraz_sloganu = studio.obraz_sloganu,
        opis = studio.opis,
        fota = studio.fota;
    const opisHtml = {__html: opis};
    const sloganHtml = {__html: slogan};
    const imgBg = {backgroundImage:'url('+obraz_sloganu+')'};



    const settingsdds = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide:1,
        nextArrow: <div className="arrow next"><PlayerBack mainColor="#000000" /></div>,
        prevArrow: <div className="arrow prev"><PlayerBack mainColor="#000000" /></div>
    };


    return (
        <Fragment>
            <section className="section o-nas dds" >
                <div className="o-nas-fluid
                            container-fluid
                            page-content_wrapper" >
                    <div className="row start-xs">
                        <div className="col col-xs-offset-1 col-xs-10 col-md-offset-2 col-xxl-5">
                            <div className="container-fluid introduction_wrapper">
                                <div className="row">
                                    <figure style={imgBg} title={slogan}>
                                        <img src={slogantransp} alt=""/>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-xs-offset-1 col-sm-offset-1 col-xs-10 col-sm-5">
                            <div className=" container-fluid
                             page-text-content
                             position-relative">
                                <div className="title-list">
                                    <p className="overtitle">
                                        <span className="bold" dangerouslySetInnerHTML={sloganHtml} />&nbsp;
                                        <span dangerouslySetInnerHTML={opisHtml} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <figure id="texture-1"
                        className="image-opener
                                page-block
                                texture-1" >
                    <div className="mask">
                        <div className="js-animate"
                             style={{
                                 backgroundImage:'url('+fota+')',
                                 transform: 'translate3d(0,' + ( -30 + (props.translate * .3) )+'px,0)'
                             }} >&nbsp;</div>
                    </div>
                </figure>
            </section>

            <MediaQuery minWidth={769}>
                <div className="separator">
                    <InView>
                        {({ inView, ref }) => (
                            <div  ref={ref}
                                  className={ inView ? 'falka-animate-backwards rotate' : 'falka-animate-backwards'}>
                                <Falka mainColor="#000000"/>
                            </div>
                        )}
                    </InView>
                </div>
            </MediaQuery>


            <header>{title}</header>
            <section className="section o-nas dds slider-section"
                     id={id}
                     data-id-mobile={idmobile}>
                <MediaQuery maxWidth={768}>
                    {/*mobile*/}
                    {props.studioList
                        .map((item,index) =>
                            <div key={index} className="row mobile">

                                <div className="col col-md-12">
                                    <header><p>{item.title}</p></header>
                                </div>

                                <div className="col col-md-12">
                                    <figure className={
                                        classnames({"double": item.diagram})
                                    }>
                                        <img src={item.cover} alt={item.title} className="cover" />
                                        <img src={item.diagram} alt={item.title} className="diagram" />

                                    </figure>
                                    <figcaption><img src={item.stamp} alt={item.title} /></figcaption>
                                </div>
                            </div>
                        )
                    }
                </MediaQuery>
                <MediaQuery minWidth={769}>
                    {/*desktop*/}
                    <Slider {...settingsdds}>
                        {props.studioList
                            .map((item,index) =>
                                <div key={index} className="row">
                                    <div className="col col-md-6">
                                        <figure className={
                                            classnames({"double": item.diagram})
                                        }>
                                            { item.diagram && <img src={item.diagram} alt={item.title} className="diagram" /> }

                                            <img src={item.cover} alt={item.title} className="cover" />
                                        </figure>
                                    </div>

                                    <div className="col col-md-6">
                                        <header>{item.title}</header>
                                        <article>{item.text}</article>
                                    </div>

                                </div>
                            )
                        }
                    </Slider>
                </MediaQuery>

            </section>
        </Fragment>
    )
};

const mapStateToProps = state => ({
    translate: state.page.calculatedScroll
});

export default connect(mapStateToProps,null)(Studio);