import React from 'react';

const SectionTitle = ({
	id,
	mobileid,
	text
                      }) => {
	return (
		<div
			className="row section-title"
			id={id}
			data-id-mobile={mobileid} >
			<div className="col col-xs-12">
				<h2>
					{text}
				</h2>
			</div>

		</div>
	);
};

export default SectionTitle;