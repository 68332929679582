import React from 'react';

const PlayDp = ({mainColor}) => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <path fill={mainColor} d="M32 0C23.5131 0 15.3737 3.37142 9.37258 9.37258C3.37142 15.3737 0 23.5131 0 32C0 40.4869 3.37142 48.6263 9.37258 54.6274C15.3737 60.6286 23.5131 64 32 64C40.4869 64 48.6263 60.6286 54.6274 54.6274C60.6286 48.6263 64 40.4869 64 32C63.9906 23.516 60.6162 15.3821 54.617 9.38298C48.6179 3.38385 40.484 0.00941103 32 0ZM21.1484 17.6711C21.1479 17.5138 21.189 17.3592 21.2677 17.223C21.3464 17.0869 21.4599 16.974 21.5964 16.896C21.7308 16.8158 21.8844 16.7735 22.0409 16.7735C22.1974 16.7735 22.3509 16.8158 22.4853 16.896L47.3031 31.232C47.4053 31.2876 47.4953 31.3631 47.568 31.454C47.6406 31.5448 47.6944 31.6493 47.7262 31.7612C47.758 31.8731 47.7671 31.9902 47.7531 32.1057C47.7391 32.2212 47.7021 32.3327 47.6444 32.4338C47.5674 32.5749 47.4514 32.6909 47.3102 32.768L22.4924 47.104C22.3569 47.1806 22.2038 47.2209 22.048 47.2209C21.8923 47.2209 21.7391 47.1806 21.6036 47.104C21.467 47.026 21.3536 46.9131 21.2748 46.777C21.1961 46.6408 21.155 46.4862 21.1556 46.3289L21.1484 17.6711Z"/>
        </svg>

    );
};

export default PlayDp;