import React, {Fragment} from 'react';


//tools
import {InView} from "react-intersection-observer";
import MediaQuery from "react-responsive";

//svg
import LogoPs from "../../../svg/LogoPs";
import ScrollerLabel from "../../../svg/ScrollerLabel";

const PsKontakt = ({data}) => {

    const {
        tel_kontaktowy_tytul,
        tel_kontaktowy,
        zapytania_tytul,
        zapytania,
        adres_tytul,
        adres_nazwa,
        adres_ulica,
        adres_kod,
    } = data;
    return (
        <Fragment>
            <section className="section kontakt"
                     id={data.id}
                     data-id-mobile={data.idmobile}>


                <InView>
                    {({ ref }) => (
                        <article ref={ref}>
                            <div className="col col1">
                                <figure className="logo mobile">
                                    <ScrollerLabel mainColor="#000000"/>
                                </figure>
                                <MediaQuery maxWidth={769}>
                                    <h3>{tel_kontaktowy_tytul}</h3>
                                    <p>{tel_kontaktowy}</p>
                                </MediaQuery>
                                <MediaQuery minWidth={769}>
                                    <div className="ftr-logo">
                                        <LogoPs mainColor="#000000"/>
                                    </div>
                                </MediaQuery>
                            </div>
                            <div className="col col2">
                                <h3>{zapytania_tytul}</h3>
                                <ul>
                                    {zapytania && zapytania.map((item,index) =>
                                        <li key={index}>
                                            <a href={'mailto:'+item.email}>{item.email}</a>
                                        </li>
                                    )}
                                </ul>
                                <MediaQuery minWidth={769}>
                                    <h3>{tel_kontaktowy_tytul}</h3>
                                    <p>{tel_kontaktowy}</p>
                                </MediaQuery>
                            </div>
                            <div className="col col3">
                                <h3>{adres_tytul}</h3>
                                <address>
                                    {adres_nazwa}<br/>
                                    {adres_ulica}<br/>
                                    {adres_kod}
                                </address>
                            </div>
                        </article>
                    )}
                </InView>
            </section>

            {/*<Footer label={rodo_label}
                    url={rodo} />*/}

        </Fragment>
    );
};

export default PsKontakt;