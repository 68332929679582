import React, {Component,Fragment} from 'react';
import {connect} from "react-redux";
import axios from "axios";

// actions
import { toggleOverlay } from "../../../actions/pageActions";
import {togglePlayer} from "../../../actions/playerActions";

// svg
import ScrollerLabel from "../../svg/ScrollerLabel";
import PlayerBack from '../../svg/PlayerBack';
import Falka from "../../svg/Falka";

// components
import PageSmoothScroller from '../../controls/PageSmoothScroller';
import MenuDdsTrigger from "../../Menu/MenuTopDds/MenuDdsTrigger";
import MenuTopDds from '../../Menu/MenuTopDds';
import Player from '../../Player';
import PlayerIOS from '../../PlayerIOS';
import Kontakt from "../../Kontakt";
import Studio from "./Studio";
import Portfolio from "../../Portfolio";

// tools
import MediaQuery from 'react-responsive';
import {isIOS} from "react-device-detect";

//config
import config from "../../../config";
import {InView} from "react-intersection-observer";
import {getDataById} from"../../../tools/getDataById";



class Dds extends Component {
    constructor(props) {
        super(props);

        this.state = {
            studio: {},
            studioList: [],
            portfolio:{},
            portfolioList:[],
            kontakt: {},
            kontaktEmails: [],
            menudds:[],
            languages: [
                {
                    name: "PL",
                    code: "pl",
                    url:"https://dynamofilm.pl",
                    active: true
                },
                {
                    name: "EN",
                    code: "en",
                    url:"http://en.dynamofilm.pl",
                    active: false
                }
            ],
            locale:[]
        };
        this.handleOverlay = this.handleOverlay.bind(this);
    }
    handleOverlay() {
        this.props.showOverlay(false);
    }

    componentDidMount() {

        const url = config.domainRelPath;
        axios
            .get(
                url+'/wp-json/api/ddsmenu/?per_page=30'
            )
            .then(res => {
                this.setState({
                    menudds: res.data
                });

            })
            .catch(error => console.log(error));
        axios
            .get(
                url+'/wp-json/acf/v3/pages/?per_page=30'
            )
            .then(res => {
                this.setState({
                    studio: getDataById(res.data, 94),
                    studioList: getDataById(res.data, 94).studios,
                    portfolio: getDataById(res.data, 92),
                    portfolioList: getDataById(res.data, 92).portfolioProdukcje,
                    kontakt: getDataById(res.data, 96),
                    kontaktEmails: getDataById(res.data, 96).zapytania
                });
                this.handleOverlay();
            })
            .catch(error => console.log(error));


            window.scrollTo(0,0)
    }
    render() {

        const { playerOpened, openPlayer} = this.props;

        return (
            <Fragment>
                <MenuTopDds menu={this.state.menudds}
                            languages={this.state.languages} />
                { playerOpened && (
                    <Fragment>

                        {isIOS ?
                            <PlayerIOS video={ this.props.selectedVideo } /> :
                            <Player video={ this.props.selectedVideo } />
                        }
                        <nav>
                            <ul>
                                <li className="home">
                                    <a href={config.domain} target="_top">
                                        <ScrollerLabel mainColor="#E4E2E0" />
                                    </a>
                                </li>
                                <li className="close-player" onClick={openPlayer}>
                                    <PlayerBack mainColor="#E4E2E0" />
                                </li>
                            </ul>
                        </nav>
                    </Fragment>
                )
                }
                <PageSmoothScroller>
                    <article className="content dds-content"
                             style={{ transform: 'matrix3d(1, 0.000174533, 0, 0, -0.000174533, 1, 0, 0, 0, 0, 1, 0, 0, -'+this.props.translate+', 0, 1)' }}>
                        <InView>
                            {({ inView, ref }) => (
                                <div  ref={ref}
                                      className="menu-trigger">
                                    <MenuDdsTrigger expanded={inView} />
                                </div>
                            )}
                        </InView>
                        <Studio studio={this.state.studio}
                                studioList={this.state.studioList}/>
                        <MediaQuery minWidth={769}>
                            <div className="separator">
                                <InView>
                                    {({ inView, ref }) => (
                                        <div  ref={ref}
                                              className={ inView ? 'falka-animate-backwards rotate' : 'falka-animate-backwards'}>
                                            <Falka mainColor="#000000"/>
                                        </div>
                                    )}
                                </InView>
                            </div>
                        </MediaQuery>
                        <Portfolio
                            portfolio={this.state.portfolio}
                            portfolioList={this.state.portfolioList}
                            showAll={false}
                            showFilter={false}
                            theme="alternatesides"
                            suffix="dds"/>
                        <MediaQuery minWidth={769}>
                            <div className="separator">
                                <InView>
                                    {({ inView, ref }) => (
                                        <div  ref={ref}
                                              className={ inView ? 'falka-animate-backwards rotate' : 'falka-animate-backwards'}>
                                            <Falka mainColor="#000000"/>
                                        </div>
                                    )}
                                </InView>
                            </div>
                        </MediaQuery>
                        <Kontakt kontakt={this.state.kontakt}
                                 kontaktEmails={this.state.kontaktEmails} />
                    </article>
                </PageSmoothScroller>

            </Fragment>

        );
    }
}

const mapStateToProps =(state) => ({
    translate: state.page.calculatedScroll,
    playerOpened: state.player.playerOpened,
    selectedVideo: state.player.selectedVideo
});

const mapDispatchToProps = dispatch => ({
    openPlayer: () => dispatch(togglePlayer()),
    showOverlay: value => dispatch(toggleOverlay(value))
});

export default connect(mapStateToProps,mapDispatchToProps)(Dds);