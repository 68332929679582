import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history'
import {createStore, applyMiddleware, compose} from "redux";
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './js/reducers'
import App from './App';
import * as serviceWorker from './serviceWorker';

import './index.css';

export const history = createBrowserHistory();

let middleware = applyMiddleware(
    routerMiddleware(history)
);
middleware = compose(
    middleware,
    //window.__REDUX_DEVTOOLS_EXTENSION__()
);

const Store = createStore(
    createRootReducer(history),
    middleware
);

ReactDOM.render(
    <Provider store={Store}>
        <App history={history} />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
