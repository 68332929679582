import React, {Component} from 'react';
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import routes from './js/routes';

//config
import config from "./js/config";

//actions
import {toggleHomeMenu, toggleOverlay} from "./js/actions/pageActions";

// components
import ScrollToTopButton from './js/components/controls/ScrollToTopButton';
import Scroller from './js/components/Scroller';
import Loading from "./js/components/Loading";

//tools
import MediaQuery from 'react-responsive';
import SocialMedia from "./js/components/SocialMedia";
const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));



class App extends Component {

    constructor(props) {
        super(props);
        this.handleOverlay = this.handleOverlay.bind(this);
    }

    componentDidMount() {
        const { toggleMenu } = this.props;
        window.onscroll = () => toggleMenu(false);


    }
    handleOverlay() {
        this.props.showOverlay(true);
    }

    render() {

        const {
            history,
            toggleMenu
        } = this.props;

        history.listen(() => {
            this.handleOverlay()
        });

        const location = this.props.location.pathname;
        const regEx = new RegExp('/', "g");
        const locationCleaned = location.replace(regEx,'');

        const isDynamoPost = location.includes('dynamo-post') || location.includes('portfolio-dp');
        const isProductionServicePost = location.includes('production-service');

        const setAppHeight = () => {

            const baseHeight = this.props.pageHeight-this.props.pageHeight*.5 + this.props.pageHeight*.05;
            //console.log("baseHeight")
            //console.log(baseHeight)
            const url = config.domainRelPathExt;

            if (!isMobile) {
                switch (location) {
                    case url + 'dynamo-post':
                        return "2700px" ;
                    case url + 'production-service':
                        return "3500px";
                    case url + 'dynamo-digital-studio':
                        return (baseHeight>200) ? (this.props.pageHeight+200)-(this.props.pageHeight+200)*.5 + ((this.props.pageHeight)+200)*.05 + 'px' : 'auto';
                    default:
                        return (baseHeight>200) ? baseHeight + 'px' : '3500px';
                }
            } else {
                return 'auto';
            }



        };

        return (
            <div className="App"
                 style={{
                     //maxHeight: setAppHeight()
                 }}
                 location={ (locationCleaned==='' || locationCleaned==='dynamo') ? 'app-home' : 'app-'+locationCleaned}>

                { this.props.displayOverlay && <Loading /> }


                        <MediaQuery minWidth={769}>
                            <div className={"bar-trigger"}
                                 onMouseEnter={() => toggleMenu(true)}/>
                            <div className={"bar"}
                                 data-socialbar-expanded={this.props.menuHomeExpanded ? 'expanded' : 'collapsed'}>
                                <SocialMedia iconColor="#000000"/>
                            </div>
                            {
                                !isDynamoPost &&
                                !isProductionServicePost &&
                                <Scroller />
                            }

                            <ScrollToTopButton/>
                        </MediaQuery>
                        <ConnectedRouter history={history}>
                            { routes }
                        </ConnectedRouter>


            </div>
        );
    }
}

App.propTypes = {
    history: PropTypes.object,
};

const mapStateToProps = (state) => ({
    pageHeight: state.page.pageHeight,
    displayOverlay: state.page.displayOverlay,
    menuHomeExpanded: state.page.menuHomeExpanded,
    location: state.router.location,

});

const mapDispatchToProps = dispatch => ({
    showOverlay: value => dispatch(toggleOverlay(value)),
    toggleMenu: value => dispatch( toggleHomeMenu(value) )
});

export default connect(mapStateToProps,mapDispatchToProps)(App);
