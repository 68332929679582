import React, {Component,Fragment} from 'react';

//svg
import ScrollerLabel from "../svg/ScrollerLabel";
import Pdf from "../svg/Pdf";
import LogoBlack from "../svg/LogoBlack";
import Falka from "../svg/Falka";

//components
import SocialMedia from "../SocialMedia";
import Footer from '../Footer';
import Newsletter from '../Newsletter';

//tools
import { InView } from 'react-intersection-observer';
import MediaQuery from 'react-responsive';

class Kontakt extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {}
    }

    render(){

        const emails = this.props.kontaktEmails;
        const kontakt = this.props.kontakt;
        const coverBg = this.props.kontakt.obraz_glowny;


        return (
            <Fragment>
                <section className="section kontakt dds"
                         id={kontakt.id}
                         data-id-mobile={kontakt.idmobile}>

                    <header>{kontakt.tytul}</header>


                    <div className="desktop">
                        <img src={coverBg} />
                    </div>

                    <MediaQuery maxWidth={768}>
                        <div className="pdf mobile">
                            <h3>{kontakt.pakiety_tytul}</h3>
                            <a href={kontakt.pakiety_link} title={kontakt.pakiety_tytul + ' - ' + kontakt.pobierz_pdf}>
                                <p>{kontakt.pobierz_pdf}</p>
                                <figure>
                                    <Pdf mainColor="#000000"/>
                                </figure>
                            </a>
                        </div>
                    </MediaQuery>

                    <InView>
                        {({ inView, ref }) => (
                        <article className={inView ? 'fade-to-black':''}
                                 ref={ref}>
                            <div className="col col1">
                                <figure className="logo mobile">
                                    <ScrollerLabel mainColor="#FFFFFF"/>
                                </figure>
                                <MediaQuery maxWidth={769}>
                                    <h3>{kontakt.tel_kontaktowy_tytul}</h3>
                                    <p>{kontakt.tel_kontaktowy}</p>
                                </MediaQuery>
                                <MediaQuery minWidth={769}>
                                    <div className="ftr-logo">
                                        <LogoBlack mainColor="#FFFFFF"/>
                                    </div>
                                    <div className="ftr-falka">
                                        <Falka mainColor="#FFFFFF"/>
                                    </div>
                                </MediaQuery>
                            </div>
                            <div className="col col2">
                                <h3>{kontakt.zapytania_tytul}</h3>
                                <ul>
                                    {emails.map((item,index) =>
                                        <li key={index}>
                                            <a href={'mailto:'+item.email}>{item.email}</a>
                                        </li>
                                    )}
                                </ul>
                                <MediaQuery minWidth={769}>
                                    <h3>{kontakt.tel_kontaktowy_tytul}</h3>
                                    <p>{kontakt.tel_kontaktowy}</p>
                                    <SocialMedia iconColor="#FFFFFF"/>
                                </MediaQuery>
                            </div>
                            <div className="col col3">
                                <h3>{kontakt.adres_tytul}</h3>
                                <address>
                                    {kontakt.adres_nazwa}<br/>
                                    {kontakt.adres_ulica}<br/>
                                    {kontakt.adres_kod}<br/>
                                    {kontakt.nip_label} {kontakt.nip}
                                </address>

                                <MediaQuery minWidth={769}>
                                    <Newsletter newsletterLabel={kontakt.newsletter_label} />

                                    <div className={ kontakt.pakiety_link ? "pdf desktop": "pdf desktop hidden" }>
                                        <a href={kontakt.pakiety_link ? kontakt.pakiety_link : "#" } title={kontakt.pakiety_tytul + ' - ' + kontakt.pobierz_pdf}>
                                            <p>{kontakt.pobierz_pdf}</p>
                                            <figure>
                                                <Pdf mainColor="#FFFFFF"/>
                                            </figure>
                                        </a>
                                    </div>
                                </MediaQuery>
                            </div>
                            <MediaQuery maxWidth={768}>
                                <SocialMedia iconColor="#FFFFFF"/>
                            </MediaQuery>
                        </article>
                        )}
                    </InView>
                </section>
                <Footer label={kontakt.rodo_label}
                        url={kontakt.rodo} />
            </Fragment>
        )
    }


};

export default Kontakt;