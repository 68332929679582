import React, {Component,Fragment} from 'react';
import {connect} from "react-redux";
import axios from "axios";

//actions
import { toggleOverlay } from "../../../actions/pageActions";
import {togglePlayer} from "../../../actions/playerActions";

// svg
import ScrollerLabel from "../../svg/ScrollerLabel";
import PlayerBack from '../../svg/PlayerBack';
import Falka from "../../svg/Falka";

// components
import PageSmoothScroller from '../../controls/PageSmoothScroller';
import MenuTrigger from '../../Menu/MenuTop/MenuTrigger';
import MenuTop from '../../Menu/MenuTop';
import Player from '../../Player';
import PlayerIOS from '../../PlayerIOS';
import ONas from "./ONas";
import OFirmie from "./OFirmie";
import Zespol from "./Zespol";
import Portfolio from "../../Portfolio";
import Kontakt from "../../Kontakt";

// tools
import MediaQuery from 'react-responsive';
import { InView } from 'react-intersection-observer';
import {isIOS} from "react-device-detect";

//config
import config from "../../../config";
import {getDataById} from"../../../tools/getDataById";

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            oNas: {},
            oFirmie: {},
            zespol: {},
            zespolLista:[],
            portfolio:{},
            portfolioList:[],
            kontakt: {},
            kontaktEmails: [],
            digital:{},
            menu:[],
            mobilemenu:[],
            languages: [
                {
                    name: "PL",
                    code: "pl",
                    url:"https://dynamofilm.pl",
                    active: true
                },
                {
                    name: "EN",
                    code: "en",
                    url:"http://en.dynamofilm.pl",
                    active: false
                }
            ],
            locale:[]
        };
        this.handleOverlay = this.handleOverlay.bind(this);
    }
    handleOverlay() {
        this.props.showOverlay(false);
    }


    componentDidMount() {

        const url = config.domainRelPath;

        axios
            .get(
                url+'/wp-json/acf/v3/pages/?per_page=30'
            )
            .then(res => {
                this.setState({
                    pages: res.data,
                    oNas: getDataById(res.data, 22),
                    oFirmie: getDataById(res.data, 87),
                    zespol: getDataById(res.data, 90),
                    zespolLista: getDataById(res.data, 90).listaCzlonkow,
                    portfolio: getDataById(res.data, 38),
                    portfolioList: getDataById(res.data, 38).portfolioProdukcje,
                    kontakt: getDataById(res.data, 40),
                    kontaktEmails: getDataById(res.data, 40).zapytania,
                    digital: getDataById(res.data, 85),
                    socialMediaList: getDataById(res.data, 5).social_media
                });
                this.handleOverlay();
            })
            .catch(error => console.log(error));
        axios
            .get(
                url+'/wp-json/api/menu/'
            )
            .then(res => {
                this.setState({
                    menu: res.data
                });

            })
            .catch(error => console.log(error));
        axios
            .get(
                url+'/wp-json/acf/v3/options/acf-options-translations'
            )
            .then(res => {
                this.setState({
                    locale: res.data.acf
                });


            })
            .catch(error => console.log(error));

        axios
            .get(
                url+'/wp-json/api/mobilemenu/'
            )
            .then(res => {
                this.setState({
                    mobilemenu: res.data
                });

            })
            .catch(error => console.log(error));

        window.scrollTo(0,0)
    }

    render() {

        const { playerOpened, openPlayer} = this.props;


        return (

            <Fragment>



                <MenuTop menu={this.state.menu}
                         mobilemenu={this.state.mobilemenu}
                         languages={this.state.languages}
                />

                { playerOpened && (
                    <Fragment>

                        {isIOS ?
                            <PlayerIOS video={ this.props.selectedVideo } /> :
                            <Player video={ this.props.selectedVideo }
                                    theme="alter"/>
                        }
                        <nav>
                            <ul>
                                <li className="home">
                                    <a href={config.domain} target="_top">
                                        <ScrollerLabel mainColor="#E4E2E0" />
                                    </a>
                                </li>
                                <li className="close-player" onClick={openPlayer}>
                                    <PlayerBack mainColor="#E4E2E0" />
                                </li>
                            </ul>
                        </nav>
                    </Fragment>
                )
                }
                <PageSmoothScroller>


                    <article className="content"
                             style={{ transform: 'matrix3d(1, 0.000174533, 0, 0, -0.000174533, 1, 0, 0, 0, 0, 1, 0, 0, -'+this.props.translate+', 0, 1)' }}>
                        <InView>
                            {({ inView, ref }) => (
                                <div  ref={ref}
                                      className="menu-trigger">
                                    <MenuTrigger expanded={inView} />
                                </div>
                            )}
                        </InView>
                        <InView>
                            {({ inView, ref }) => (
                                <div ref={ref}>
                                    <ONas  oNas={this.state.oNas}
                                           onStage={inView} />
                                </div>
                            )}
                        </InView>
                        <OFirmie oFirmie={this.state.oFirmie} />

                        <Portfolio
                            portfolio={this.state.portfolio}
                            portfolioList={this.state.portfolioList}
                            showAll={false}
                            showFilter={false}
                            theme="alternatesides"
                        />
                        <Zespol zespol={this.state.zespol}
                                zespolLista={this.state.zespolLista}/>
                        <MediaQuery minWidth={769}>
                            <div className="separator">
                                <InView>
                                    {({ inView, ref }) => (
                                        <div  ref={ref}
                                              className={ inView ? 'falka-animate-backwards rotate' : 'falka-animate-backwards'}>
                                            <Falka mainColor="#000000"/>
                                        </div>
                                    )}
                                </InView>
                            </div>
                        </MediaQuery>
                        <Kontakt kontakt={this.state.kontakt}
                                 kontaktEmails={this.state.kontaktEmails} />
                    </article>
                </PageSmoothScroller>
            </Fragment>

        );
    }
}
const mapStateToProps =(state) => ({
    translate: state.page.calculatedScroll,
    playerOpened: state.player.playerOpened,
    selectedVideo: state.player.selectedVideo
});

const mapDispatchToProps = dispatch => ({
    openPlayer: () => dispatch(togglePlayer()),
    showOverlay: value => dispatch(toggleOverlay(value))
});

export default connect(mapStateToProps,mapDispatchToProps)(Home);