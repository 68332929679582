import React from 'react';

const SocialYoutube = ({mainColor}) => {
    return (
        <svg version="1.1" id="social_youtube" xmlns="http://www.w3.org/2000/svg" width="34.976px" height="35px" viewBox="288.424 383.237 34.976 35">
            <title>youtube logo</title>
            <path  fill={mainColor} d="M302.433,405.618V395.18l4.23,2.396l5.308,2.847L302.433,405.618z M321.831,389.727c-0.917-0.979-2.183-1.557-3.522-1.608
	c-4.954-0.402-12.417-0.499-12.417-0.499l0,0c0,0-7.415,0.096-12.369,0.466c-1.357,0.04-2.642,0.618-3.571,1.608
	c-0.727,1.108-1.169,2.379-1.287,3.699c0,0-0.241,3.024-0.241,5.984v2.831c0,2.976,0.241,5.951,0.241,5.951
	c0.137,1.326,0.601,2.597,1.351,3.699c1.051,1.003,2.44,1.577,3.893,1.608c2.831,0.306,12.031,0.386,12.031,0.386
	s7.447,0,12.401-0.386c1.353-0.017,2.637-0.6,3.538-1.608c0.739-1.105,1.192-2.376,1.319-3.699c0,0,0.226-2.976,0.226-5.951v-2.848
	c0-2.959-0.226-5.967-0.226-5.967C323.064,392.075,322.593,390.813,321.831,389.727z"/>
    </svg>
    );
};

export default SocialYoutube;