import React, {useEffect, useState, Fragment, useRef} from 'react';
import {connect} from "react-redux";
import axios from "axios";

// actions
import {toggleOverlay} from "../../../actions/pageActions";
import {togglePlayer} from "../../../actions/playerActions";

// svg
import ScrollerLabel from "../../svg/ScrollerLabel";
import PlayerBack from '../../svg/PlayerBack';

// components
import PageSmoothScroller from './DpSmoothScroller';
import MenuPsTrigger from "../../Menu/MenuTopPs/MenuPsTrigger";
import MenuTopPs from '../../Menu/MenuTopPs';
import SectionTitle from "../../SectionTitle";
import DpPortfolio from "../../PortfolioDp";
import Player from '../../Player';
import PlayerIOS from '../../PlayerIOS';
import ScrollerDp from "../../ScrollerDp";
import PsAboutUs from "./Onas";
import DsKontakt from "./Kontakt";

// tools
import {isIOS} from "react-device-detect";
import {InView} from "react-intersection-observer";

//config
import config from "../../../config";
import {getDataById} from "../../../tools/getDataById";
import MediaQuery from "react-responsive";

const ProductionService = (
	{
		articleContainerRef,
		showOverlay,
		openPlayer,
		playerOpened,
		translate,
		selectedVideo
	}) => {

	const [menu, setMenu] = useState([]);

	const [page, setPage] = useState({
		oNas: {},
		sets: {
			bg: {
				sq: null,
				panorama: null,
			},
			id: {
				desktop: null,
				mobile: null,
			},
			title: null,
			list: [],
			showTitles: true,
		},
		locations: {
			bg: {
				sq: null,
				panorama: null,
			},
			id: {
				desktop: null,
				mobile: null,
			},
			title: null,
			list: [],
			showTitles: true,
		},
		kontakt:{},
		psmenu: [],
		languages: [
			{
				name: "PL",
				code: "pl",
				url: "https://dynamofilm.pl",
				active: true
			},
			{
				name: "EN",
				code: "en",
				url: "http://en.dynamofilm.pl",
				active: false
			}
		],
		locale: []
	});

	useEffect(() => {
		const url = config.domainRelPath;

		axios
			.get(
				url + '/wp-json/api/psmenu/?per_page=30'
			)
			.then(res => {
				setMenu(res.data);
			})
			.catch(error => console.log(error));

		axios
			.get(
				url + '/wp-json/acf/v3/pages/'
			)
			.then(res => {
				setPage({
					...page,
					oNas: getDataById(res.data, 1133),
					sets: {
						bg: {
							sq: getDataById(res.data, 1163).tlo_transp,
							panorama: getDataById(res.data, 1163).tlo_transp_panorama,
						},
						id: {
							desktop: getDataById(res.data, 1163).id,
							mobile: getDataById(res.data, 1163).idmobile,
						},
						title: getDataById(res.data, 1163).title,
						list: getDataById(res.data, 1163).portfolioSety,
						showTitles: getDataById(res.data, 1163).show_titles
					},
					locations: {
						bg: {
							sq: getDataById(res.data, 1245).tlo_transp,
							panorama: getDataById(res.data, 1245).tlo_transp_panorama,
						},
						id: {
							desktop: getDataById(res.data, 1245).loc_id,
							mobile: getDataById(res.data, 1245).loc_idmobile,
						},
						title: getDataById(res.data, 1245).title,
						list: getDataById(res.data, 1245).portfolioLokacje,
						showTitles: getDataById(res.data, 1245).show_titles
					},
					kontakt: getDataById(res.data, 1286),
					commonData: getDataById(res.data, 855)
				});
				showOverlay(false);
			})
			.catch(error => console.log(error));
		window.scrollTo(0, 0)

	}, [
		setMenu,
		setPage,
		showOverlay
	]);

	const [setsDimensions, setSetsDimensions] = useState({});

	const setsRef = useRef(null);

	useEffect(() => {
		if (setsRef) {
			setTimeout(() => {
				setSetsDimensions(setsRef.current.getBoundingClientRect());
			}, 3000)
		}
	}, [setsRef]);


	return (
		<Fragment>
			<MenuTopPs menu={menu}
			           languages={page.languages}/>
			{playerOpened && (
				<Fragment>

					{isIOS ?
						<PlayerIOS video={selectedVideo}/> :
						<Player video={selectedVideo}/>
					}
					<nav>
						<ul>
							<li className="home">
								<a href={config.domain} target="_top">
									<ScrollerLabel mainColor="#E4E2E0"/>
								</a>
							</li>
							<li className="close-player" onClick={openPlayer}>
								<PlayerBack mainColor="#E4E2E0"/>
							</li>
						</ul>
					</nav>
				</Fragment>
			)
			}
			<PageSmoothScroller>
				<article
					ref={articleContainerRef}
					className="content dp-content ps-content"
					style={{transform: 'matrix3d(1, 0.000174533, 0, 0, -0.000174533, 1, 0, 0, 0, 0, 1, 0, 0, -' + translate + ', 0, 1)'}}>
					<InView>
						{({inView, ref}) => (
							<div ref={ref}
							     className="menu-trigger">
								<MenuPsTrigger expanded={inView}/>
							</div>
						)}
					</InView>

					<InView>
						{({ref}) => (
							<div ref={ref}>
								<PsAboutUs
									id={{
										desktop: page.oNas.id,
										mobile: page.oNas.idmobile,
									}}
									slogan={{
										transp: page.oNas.slogantranp,
										img: page.oNas.obraz_sloganu,
										txt: page.oNas.slogan,
									}}
									text={page.oNas.opis}
									cover={page.oNas.fota}
									layout={page.oNas.ps_info_layout}
								/>
							</div>
						)}
					</InView>

					<div />


					{page.sets.title &&
						<SectionTitle
							text={page.sets.title}
							id={page.sets.id.desktop}
					        mobileid={page.sets.id.mobile}
						/> }

					<div
						ref={setsRef}
						className="ps-content__wrapper"
					>
						<InView>
							{({ref}) => (
								<div ref={ref} className="row">
									<div className="col col-xs-12 col-md-1 ps-content__scroller-holder"
									     style={{position: 'relative'}}>
										<div>
											<MediaQuery minWidth={769}>
												<ScrollerDp
													dither={1.8}
													light={false}
													containerHeight={setsDimensions.height}
												/>
											</MediaQuery>
										</div>
									</div>
									<div className="col col-xs-12 col-md-11 ps-content__lists">
										{page.sets.list.length > 0 &&
											<DpPortfolio
												picsOnly
												lightMode
												disableInnerScroller
												accentColorRGBA={page.commonData.accent_color_rgba}
												clips={page.sets.list}
												showTitles={page.sets.showTitles}
												transpBg={page.sets.bg.sq}
												transpBgFull={page.sets.bg.panorama}
											/>
										}

										{page.locations.title &&
											<SectionTitle
												id={page.locations.id.desktop}
												mobileid={page.locations.id.mobile}
												text={page.locations.title}/>
										}

										{page.locations.list.length > 0 &&
											<DpPortfolio
												picsOnly
												lightMode
												disableInnerScroller
												accentColorRGBA={page.commonData.accent_color_rgba}
												clips={page.locations.list}
												showTitles={page.locations.showTitles}
												transpBg={page.locations.bg.sq}
												transpBgFull={page.locations.bg.panorama}
											/>
										}
									</div>

								</div>
							)}
						</InView>
					</div>
					<DsKontakt data={page.kontakt} />
				</article>
			</PageSmoothScroller>

		</Fragment>
	);
};


const mapStateToProps = (state) => ({
	translate: state.page.calculatedScroll,
	playerOpened: state.player.playerOpened,
	selectedVideo: state.player.selectedVideo
});

const mapDispatchToProps = dispatch => ({
	openPlayer: () => dispatch(togglePlayer()),
	showOverlay: value => dispatch(toggleOverlay(value))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductionService);