import React from 'react';

const Footer = ( props ) => {
    return (
        <div>
            <footer className="footer">
                <ul>
                    <li><span>Dynamo&copy; 2019</span></li>
                    { props.url &&
                        <li>
                            <a href={props.url} title={props.label}>{props.label}</a>
                        </li>
                    }

                </ul>
            </footer>
        </div>
    );
};

export default Footer;