import React from 'react';

const LogoBlack = ({mainColor}) => {
    return (
        <svg version="1.1" width="114" height="32" id="logo_black" xmlns="http://www.w3.org/2000/svg" viewBox="31.865 320.65 547.836 153.831">
            <path fill={mainColor} d="M539.909,378.879L539.909,378.879c0,11.197-9.077,20.273-20.273,20.273s-20.273-9.077-20.273-20.274
                c0.001-11.163,9.024-20.226,20.188-20.273v-19.798c-22.179,0.06-40.109,18.087-40.05,40.266s18.087,40.109,40.266,40.05
                c22.137-0.06,40.05-18.021,40.05-40.158l0,0L539.909,378.879z M80.661,399.238v19.885c22.016-0.094,39.846-17.905,39.964-39.92l0,0
                V320.65H100.74v23.749c-6.136-3.64-13.14-5.557-20.273-5.548c-22.078,0-40.016,17.821-40.158,39.899h19.885
                c0.12-11.196,9.293-20.175,20.489-20.056s20.175,9.293,20.056,20.489C100.62,390.218,91.697,399.133,80.661,399.238 M33.508,338.138
                c-0.05-2.032,0.729-3.996,2.159-5.44c2.869-2.981,7.612-3.072,10.593-0.202c0.069,0.066,0.137,0.133,0.202,0.202
                c2.952,2.993,2.952,7.802,0,10.795c-2.938,2.981-7.735,3.016-10.717,0.078c-0.026-0.026-0.052-0.052-0.078-0.078
                c-1.422-1.44-2.201-3.395-2.159-5.419 M47.693,344.55c3.596-3.621,3.596-9.463,0-13.084c-3.615-3.619-9.479-3.622-13.099-0.006
                c-0.002,0.002-0.004,0.004-0.006,0.006c-3.613,3.587-3.634,9.425-0.047,13.038c0.016,0.016,0.031,0.031,0.047,0.046
                C38.215,348.148,44.066,348.148,47.693,344.55 M44.8,342.391c0.999-0.753,1.709-1.829,2.008-3.044h-2.937
                c-0.437,1.093-1.5,1.805-2.677,1.792c-0.821,0.049-1.619-0.286-2.159-0.907c-0.544-0.589-0.851-1.357-0.863-2.159
                c-0.005-0.805,0.305-1.58,0.863-2.159c0.552-0.602,1.344-0.926,2.159-0.885c1.209-0.058,2.308,0.704,2.677,1.857h2.937
                c-0.228-1.217-0.916-2.3-1.922-3.023c-1.057-0.813-2.357-1.247-3.691-1.23c-1.513-0.032-2.975,0.544-4.06,1.598
                c-1.096,1.01-1.708,2.439-1.684,3.93c-0.005,1.473,0.605,2.882,1.684,3.886c1.108,1.061,2.59,1.643,4.124,1.619
                c1.277,0.018,2.523-0.393,3.541-1.166 M440.745,338.807c-9.52,0.01-18.622,3.91-25.196,10.795
                c-13.443-13.922-35.626-14.31-49.548-0.867c-6.615,6.388-10.454,15.121-10.689,24.313l0,0v43.613h19.906v-43.181
                c0.107-8.347,6.961-15.026,15.308-14.919c8.348,0.107,15.026,6.96,14.919,15.308v42.706h20.122v-43.483
                c0.209-8.347,7.145-14.944,15.491-14.735c8.347,0.208,14.944,7.144,14.735,15.491l0,0v42.728h19.929v-43.613l0,0
                C475.253,353.977,459.737,338.825,440.745,338.807 M233.176,338.807c-19.013-0.006-34.562,15.15-35.041,34.156l0,0v24.225
                l-0.281,19.432h20.209v-43.332c0.154-8.347,7.047-14.988,15.394-14.833c8.347,0.155,14.987,7.047,14.833,15.394v42.728h19.928
                v-43.613l0,0C267.725,353.962,252.183,338.813,233.176,338.807 M311.614,397.857c-10.648,0-19.28-8.633-19.28-19.281
                c0-10.648,8.632-19.28,19.28-19.28c10.647,0,19.28,8.632,19.28,19.28C330.894,389.225,322.261,397.857,311.614,397.857
                 M311.614,340.383c-21.094-0.039-38.225,17.029-38.264,38.123s17.028,38.225,38.122,38.264c6.888,0.014,13.65-1.837,19.572-5.354
                v5.462h18.719v-35.581c0-0.907,0-1.813,0-2.72c0-21.094-17.1-38.193-38.192-38.193l0,0"/>
            <path fill={mainColor} d="M174.083,341.117v42.728c0.421,7.942-5.677,14.722-13.619,15.143c-7.941,0.421-14.722-5.676-15.143-13.618
                c-0.017-0.321-0.023-0.642-0.019-0.963l0.734-43.181h-19.216l-0.475,43.613l0,0c0.453,18.935,15.113,34.156,33.313,34.156
                c5.02-0.027,9.962-1.24,14.423-3.541v28.65h18.956V341.117H174.083z"/>
            <path fill={mainColor} d="M569.769,396.691c-5.497-0.013-9.963,4.434-9.975,9.932c-0.012,5.496,4.435,9.963,9.932,9.975s9.963-4.436,9.975-9.932
                c0-0.008,0-0.015,0-0.021c0.012-5.485-4.425-9.941-9.91-9.953C569.783,396.691,569.777,396.691,569.769,396.691"/>
            <path fill={mainColor} d="M550.294,441.75c-2.083-0.035-4.146,0.416-6.023,1.317c-1.798,0.94-3.295,2.37-4.318,4.123
                c-0.618-1.508-1.614-2.831-2.893-3.843c-4.005-2.229-8.9-2.121-12.804,0.28c-1.221,0.992-2.258,2.19-3.065,3.541v-4.663h-8.507
                v31.91h8.874v-19.237c-0.077-1.348,0.167-2.694,0.713-3.929c1.097-1.668,3.05-2.564,5.03-2.311c1.779-0.199,3.496,0.72,4.318,2.311
                c0.48,1.318,0.687,2.722,0.604,4.123v19.043h8.809v-19.043c0.023-1.266,0.326-2.512,0.885-3.648
                c0.903-1.915,2.899-3.068,5.009-2.893c1.745-0.228,3.453,0.626,4.318,2.158c0.436,0.932,0.643,1.953,0.604,2.979v20.36h8.981
                v-20.145c0.037-1.582-0.071-3.165-0.323-4.728c-0.271-1.508-0.925-2.921-1.9-4.103c-1.032-1.27-2.369-2.258-3.886-2.871
                c-1.376-0.539-2.841-0.817-4.318-0.82 M505.063,431.105h-8.636v43.182h8.636V431.105z M470.906,430.652
                c-4.317,0-7.211,0.799-8.636,2.354c-1.76,2.157-2.619,4.911-2.396,7.687v2.159h-4.793v5.894h4.793v25.671h8.636v-25.778h5.743
                v-5.895h-5.743v-0.583c-0.093-1.145,0.039-2.296,0.389-3.39c0.324-0.713,1.145-1.079,2.526-1.079h1.403h1.209v-6.758l-1.728-0.13
                h-1.619 M479.435,474.48h8.637v-31.889h-8.637V474.48z M479.435,438.576h8.637v-7.643h-8.637V438.576z"/>
        </svg>
    );
};

export default LogoBlack;