import React, {Component} from 'react';
import ScrollerLabel from '../svg/ScrollerLabel';

class Scroller extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scrollerBtmLineLength: 0,
            scrollerTopLineLength: 0
        }
        this.handleScroll = this.handleScroll.bind(this)
    }

    componentDidMount() {
        this.setState({
            scrollerBtmLineLength: document.documentElement.clientHeight/6
        });
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        this.setState({
            scrollerBtmLineLength: (document.documentElement.clientHeight/6) - ( scrollTop / 30 ),
            scrollerTopLineLength:   scrollTop / 30
        });
    };

    render() {

        const topLineStyle = {
            height: this.state.scrollerTopLineLength + 'px'
        };

        const btmLineStyle = {
            height: this.state.scrollerBtmLineLength + 'px'
        };

        return (
            <div className="scroller">
                <figure className="scroller__progress" style={topLineStyle}>
                    <div className="scroller__progress__line"></div>
                </figure>
                <header className="scroller__label">
                    <figure>
                        <ScrollerLabel mainColor="#000" />
                        <span>scroll</span>
                    </figure>
                    {this.props.scrollerLabel}
                </header>
                <figure className="scroller__progress" style={btmLineStyle}>
                    <div className="scroller__progress__line"></div>
                </figure>
            </div>
        );
    }
}

export default Scroller;