import React, {Component,Fragment} from 'react';
import ReactDOM from 'react-dom';
import {connect} from "react-redux";

//tools
import { InView } from 'react-intersection-observer';
import MediaQuery from 'react-responsive';

class AnimatedPortfolioItem extends Component {
    constructor(props) {
        super(props);
        this.handlePositionY = this.handlePositionY.bind(this);
        this.handlePositionYmobile = this.handlePositionYmobile.bind(this);
    }

    handlePositionY(){
        const rect = ReactDOM.findDOMNode(this).getBoundingClientRect().top;
        return { transform: 'translate3d(0px, -' + (rect/4) + 'px, 0px)'}
    }

    handlePositionYmobile(){
        const rect = ReactDOM.findDOMNode(this).getBoundingClientRect().top;
        return { transform: 'translate3d(0px, -' + (rect/8) + 'px, 0px)'}
    }

    render() {
        const {src,alt,index} = this.props;
        return (
            <Fragment>
                <MediaQuery maxWidth={768}>
                    <InView>
                        {({ inView, ref }) => (
                            <figure ref={ref}
                                    id={"portfolio-item-"+index}>
                                <img src={src}
                                     alt={alt}
                                     style={inView ? this.handlePositionYmobile() : null}/>
                            </figure>
                        )}
                    </InView>
                </MediaQuery>
                <MediaQuery minWidth={769}>
                    <InView>
                        {({ inView, ref }) => (
                            <figure ref={ref}
                                    id={"portfolio-item-"+index}>
                                <img src={src}
                                     alt={alt}
                                     style={inView ? this.handlePositionY() : null}/>
                            </figure>
                        )}
                    </InView>
                </MediaQuery>
            </Fragment>

        );
    }
}

const mapStateToProps =(state) => ({
    translate: state.page.calculatedScroll
});

export default connect(mapStateToProps,null)(AnimatedPortfolioItem);