import React from 'react';
import classnames from 'classnames';

const MenuLang = ({ languages, activeLanguage, setActiveLanguage }) => (
    <nav className="lang">
        <ul>
            {languages.map(lang => (
                <li key={lang.code}
                    className={classnames(
                        {
                            "active":lang.active
                        }
                    )}>
                    <a href={lang.url} target="_top">
                        {lang.name}
                    </a>
                </li>
            ))}
        </ul>
    </nav>
);

export default MenuLang;