import React, {useState, useEffect, useCallback} from 'react';

//components
import ScrollerLabel from '../svg/ScrollerLabel';

//tools
import classnames from "classnames";

const ScrollerDp = (
	{
		light,
		scrollerLabel,
		containerHeight,
		dither = 1.3
	}) => {

	const [state, setState] = useState({
		scrollerBtmLineLength: 0,
		scrollerTopLineLength: 0
	});

	const handleScroll = useCallback(() => {

		const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
		const scrollTopDither = scrollTop * dither

		setState({
			scrollerBtmLineLength: containerHeight - scrollTopDither,
			scrollerTopLineLength: scrollTopDither
		});

	}, [setState, containerHeight]);

	useEffect(() => {
		setState({
			...state,
			scrollerBtmLineLength: containerHeight
		});
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		}
	}, [setState, handleScroll, containerHeight]);


	const topLineStyle = {
		height: state.scrollerTopLineLength + 'px'
	};

	const btmLineStyle = {
		height: state.scrollerBtmLineLength + 'px'
	};

	return (
		<div className={classnames(
			"scroller scroller-dp", {
				"scroller-dp__light": light
			}
		)}>
			<figure className="scroller__progress" style={topLineStyle}>
				<div className="scroller__progress__line"></div>
			</figure>
			<header className="scroller__label">
				<figure>
					<ScrollerLabel mainColor={light ? "#FFFFFF" : "#000000"}/>
					<span>scroll</span>
				</figure>
				{scrollerLabel}
			</header>
			<figure className="scroller__progress" style={btmLineStyle}>
				<div className="scroller__progress__line"></div>
			</figure>
		</div>
	);

}

export default ScrollerDp;