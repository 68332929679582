import React, {Component} from 'react';

//svg
import SocialVimeo from '../svg/SocialVimeo';
import SocialYoutube from '../svg/SocialYoutube';
import SocialFacebook from '../svg/SocialFacebook';
import SocialInstagram from '../svg/SocialInstagram';

class SocialMediaIcon extends Component {
    components = {
        youtube: SocialYoutube,
        vimeo: SocialVimeo,
        facebook: SocialFacebook,
        instagram: SocialInstagram
    };
    render() {
        const TagName = this.components[this.props.tag.toLowerCase()];
        return <TagName mainColor={this.props.mainColor} />
    }
}

export default SocialMediaIcon;