import React from 'react';

const LogoDds = ({mainColor}) => {
    return (
        <svg version="1.1" id="Logo_DDS" xmlns="http://www.w3.org/2000/svg" width="114px" height="32px" viewBox="14.75 316.5 573.114 162.251" >
            <title>Dynamo DS logo</title>
            <g>
            <path  fill={mainColor} d="M557.998,445.684c-1.668-0.062-3.311,0.428-4.676,1.391c-0.854,0.713-1.581,1.568-2.147,2.527v-3.37h-5.771v22.83h5.939
        V456.72c-0.033-1.192,0.167-2.38,0.59-3.496c0.703-1.675,2.401-2.711,4.212-2.569c1.364-0.132,2.675,0.564,3.328,1.769
        c0.348,0.885,0.505,1.832,0.463,2.78v13.9h6.108v-15.416c0.23-2.283-0.592-4.547-2.232-6.15c-1.644-1.281-3.688-1.938-5.771-1.854
        M541.529,457.688c0.035-3.06-0.992-6.037-2.906-8.425c-2.169-2.545-5.429-3.893-8.762-3.622c-3.344-0.264-6.613,1.082-8.804,3.622
        c-3.812,5.079-3.812,12.065,0,17.145c2.211,2.508,5.47,3.834,8.804,3.58c3.323,0.268,6.576-1.062,8.762-3.58
        c1.924-2.383,2.952-5.363,2.906-8.425 M529.819,465.145c-1.629,0.146-3.22-0.553-4.212-1.854c-1.058-1.56-1.562-3.428-1.433-5.308
        c-0.12-1.865,0.384-3.718,1.433-5.266c1.912-2.222,5.263-2.473,7.484-0.561c0.201,0.173,0.388,0.36,0.561,0.561
        c1.033,1.554,1.522,3.405,1.391,5.266c0.11,1.872-0.376,3.73-1.391,5.308c-0.992,1.301-2.583,2-4.212,1.854 M507.788,469.356h6.065
        v-23.125h-6.065V469.356z M514.191,437.807h-6.065v5.519h6.065V437.807z M495.026,450.443v14.322
        c-0.089,1.138,0.257,2.267,0.969,3.159c1.656,1.26,3.755,1.792,5.813,1.474h2.822v-4.549h-1.18c-1.222,0-1.938,0-2.19-0.379
        c-0.293-0.525-0.411-1.131-0.337-1.728v-12.047h3.707v-4.212h-3.707v-6.403h-5.896v6.36h-3.202v4.213L495.026,450.443z
        M468.193,446.231v13.774c-0.097,2.025,0.233,4.049,0.97,5.938c1.293,2.52,4.009,3.978,6.823,3.665
        c1.044,0.013,2.082-0.144,3.075-0.463c1.001-0.398,1.916-0.984,2.695-1.728c0.322-0.257,0.594-0.571,0.801-0.927l0.421-0.632v3.243
        h5.645v-22.872h-5.896v12.637c0.04,1.207-0.161,2.409-0.59,3.538c-0.73,1.643-2.422,2.641-4.213,2.485
        c-1.322,0.146-2.597-0.542-3.201-1.727c-0.35-0.942-0.507-1.945-0.463-2.949v-13.984H468.193z M462.97,438.186h-5.981v30.918h5.981
        V438.186z M453.029,457.688c0.035-3.06-0.992-6.037-2.906-8.425c-4.897-4.734-12.667-4.734-17.565,0
        c-3.869,5.061-3.869,12.085,0,17.145c4.898,4.734,12.668,4.734,17.565,0c1.924-2.383,2.952-5.363,2.906-8.425 M441.319,465.145
        c-1.629,0.146-3.22-0.553-4.212-1.854c-1.029-1.572-1.53-3.432-1.433-5.308c-0.119-1.865,0.384-3.718,1.433-5.266
        c1.912-2.222,5.263-2.473,7.484-0.561c0.201,0.173,0.389,0.36,0.561,0.561c1.049,1.548,1.553,3.4,1.433,5.266
        c0.13,1.88-0.375,3.748-1.433,5.308c-0.992,1.301-2.583,2-4.212,1.854 M405.81,462.069c0.073,2.158,1.09,4.175,2.779,5.519
        c4.853,2.949,10.944,2.949,15.796,0c1.656-1.364,2.589-3.417,2.527-5.561c0.006-1.586-0.633-3.105-1.769-4.212
        c-1.522-1.311-3.371-2.184-5.35-2.527c-2.12-0.374-4.205-0.924-6.234-1.644c-0.578-0.275-0.93-0.877-0.885-1.516
        c0.032-0.575,0.348-1.097,0.843-1.391c0.838-0.495,1.811-0.716,2.78-0.632c1.249-0.115,2.484,0.333,3.37,1.222
        c0.365,0.538,0.583,1.162,0.632,1.812h5.981c-0.03-2.343-1.172-4.532-3.075-5.897c-2.125-1.212-4.55-1.796-6.992-1.685
        c-2.548-0.157-5.062,0.655-7.035,2.274c-1.58,1.362-2.488,3.347-2.485,5.434c-0.13,1.574,0.458,3.122,1.601,4.213
        c1.617,1.219,3.489,2.054,5.477,2.442c2.142,0.397,4.242,0.989,6.276,1.77c0.533,0.337,0.852,0.928,0.842,1.559
        c0.011,0.688-0.409,1.312-1.053,1.559c-0.888,0.393-1.853,0.58-2.822,0.547c-1.512,0.183-3.032-0.259-4.212-1.221
        c-0.505-0.652-0.785-1.45-0.801-2.275L405.81,462.069z M390.308,438.48h-5.56v30.918h5.939L390.308,438.48z M367.773,455.751
        c-1.829,0.146-3.614,0.632-5.266,1.433c-1.96,1.299-3.07,3.551-2.906,5.896c-0.117,1.903,0.625,3.758,2.021,5.055
        c1.451,1.208,3.296,1.838,5.182,1.77c1.479-0.003,2.933-0.395,4.212-1.138c1.061-0.615,2.025-1.381,2.864-2.274
        c-0.025,0.463-0.025,0.927,0,1.391c0.099,0.442,0.226,0.879,0.38,1.306h6.486v-0.885c-0.346-0.169-0.647-0.413-0.885-0.716
        c-0.229-0.487-0.345-1.021-0.337-1.559c0-1.054,0-1.938,0-2.654v-10.572c0.25-2.305-0.914-4.533-2.948-5.645
        c-2.086-1.01-4.381-1.515-6.697-1.475c-3.048-0.313-6.067,0.807-8.172,3.033c-1.044,1.437-1.631,3.153-1.686,4.928h5.771
        c0.1-0.761,0.39-1.485,0.842-2.105c0.821-0.785,1.945-1.17,3.075-1.054c0.999-0.06,1.998,0.128,2.906,0.548
        c0.671,0.404,1.049,1.158,0.969,1.938c-0.01,0.79-0.543,1.479-1.306,1.686c-0.762,0.274-1.554,0.458-2.358,0.547L367.773,455.751z
        M373.459,460.511c0.179,1.598-0.453,3.179-1.685,4.212c-1.062,0.744-2.327,1.142-3.623,1.138
        c-0.763,0.007-1.505-0.246-2.106-0.716c-0.605-0.56-0.917-1.369-0.842-2.19c-0.054-1.038,0.484-2.018,1.39-2.527
        c0.878-0.422,1.816-0.706,2.78-0.843l1.727-0.716l1.602-0.379c0.396-0.163,0.775-0.36,1.137-0.59L373.459,460.511z
        M347.722,450.991v13.774c-0.108,1.146,0.257,2.288,1.012,3.159c1.656,1.26,3.755,1.792,5.813,1.474h2.822v-4.549h-1.18
        c-1.222,0-1.938,0-2.19-0.379c-0.293-0.525-0.411-1.131-0.337-1.728v-12.047h3.707v-4.212h-3.707v-6.403h-5.56v6.36h-3.159v4.213
        L347.722,450.991z M335.465,469.398h6.065v-23.167h-5.687L335.465,469.398z M341.909,437.807h-6.065v5.519h6.065V437.807z
        M318.279,445.516c-2.822-0.109-5.511,1.204-7.161,3.496c-1.727,2.463-2.612,5.417-2.527,8.424
        c-0.169,3.031,0.758,6.021,2.612,8.425c1.747,2.053,4.341,3.187,7.034,3.075c2.676,0.228,5.229-1.165,6.486-3.538v1.896
        c0.098,1.592-0.146,3.186-0.716,4.675c-0.832,1.512-2.501,2.362-4.212,2.148c-1.141,0.105-2.283-0.177-3.243-0.8
        c-0.423-0.407-0.703-0.938-0.801-1.517h-6.613c0.072,2.117,1.215,4.053,3.033,5.139c2.182,1.226,4.66,1.823,7.16,1.728
        c3.719,0.465,7.418-0.996,9.814-3.876c1.111-2.034,1.622-4.342,1.475-6.655v-21.903h-5.813v3.285
        c-0.752-1.48-2.007-2.646-3.539-3.285c-0.947-0.391-1.966-0.577-2.99-0.548 M319.795,464.176c-1.888,0.099-3.61-1.073-4.213-2.864
        c-0.473-1.134-0.702-2.354-0.674-3.581c-0.034-1.319,0.181-2.634,0.632-3.875c0.572-1.825,2.302-3.036,4.213-2.948
        c1.42-0.054,2.78,0.571,3.664,1.685c1.024,1.446,1.516,3.203,1.391,4.971c0.144,1.802-0.364,3.596-1.433,5.055
        c-0.893,1.015-2.187,1.585-3.538,1.559 M298.776,469.104h6.065v-22.872h-6.276L298.776,469.104z M304.631,437.807h-6.066v5.519
        h6.066V437.807z M282.222,464.766c-1.513,0.096-2.961-0.628-3.791-1.896c-1.626-3.13-1.626-6.854,0-9.983
        c0.817-1.313,2.289-2.074,3.833-1.979c1.109-0.01,2.186,0.379,3.033,1.095c1.562,1.578,2.335,3.773,2.105,5.981
        c0.222,1.759-0.117,3.542-0.969,5.097c-0.881,1.256-2.344,1.972-3.875,1.896 M281.253,445.895c-2.799-0.08-5.471,1.17-7.203,3.369
        c-1.916,2.666-2.866,5.905-2.696,9.184c-0.134,3.046,0.839,6.038,2.738,8.424c1.617,2.074,4.11,3.273,6.739,3.244
        c2.888,0.183,5.618-1.33,6.993-3.876v3.159h5.813v-31.171h-6.234v10.91c-0.626-1.063-1.528-1.937-2.611-2.527
        c-1.128-0.641-2.41-0.961-3.707-0.927"/>
        <path fill={mainColor} d="M546.246,381.994L546.246,381.994c-0.001,11.632-9.431,21.061-21.063,21.061c-11.632-0.001-21.061-9.431-21.061-21.063
        c0.001-11.549,9.303-20.944,20.852-21.059v-20.851c-23.265,0-42.123,18.859-42.123,42.123s18.858,42.122,42.123,42.122
        c23.264,0,42.122-18.858,42.122-42.122l0,0l0,0L546.246,381.994z M65.753,403.309v20.767c23.002-0.16,41.622-18.743,41.828-41.744
        l0,0v-61.246H86.815v24.768c-20.146-11.633-45.908-4.732-57.542,15.414c-3.619,6.268-5.563,13.363-5.642,20.601H44.44
        c0.21-11.63,9.809-20.887,21.438-20.676c11.63,0.21,20.888,9.809,20.677,21.439c-0.205,11.365-9.394,20.517-20.76,20.678
        M16.428,339.366c-0.032-2.14,0.821-4.198,2.358-5.687c2.979-3.106,7.913-3.208,11.019-0.229c0.078,0.074,0.154,0.15,0.229,0.229
        c3.139,3.141,3.139,8.231,0,11.373c-1.464,1.518-3.494,2.357-5.603,2.317c-2.109,0.069-4.152-0.739-5.645-2.233
        c-1.529-1.494-2.381-3.548-2.358-5.687 M31.297,346.274c3.045-3.62,2.302-9.53,0-13.774c-3.742-3.792-9.85-3.833-13.642-0.09
        c-0.03,0.03-0.061,0.06-0.091,0.09c-3.753,3.792-3.753,9.898,0,13.69c1.799,1.852,4.285,2.874,6.866,2.822
        c2.579,0.042,5.063-0.979,6.866-2.822 M28.264,343.958c1.047-0.796,1.79-1.925,2.106-3.201h-3.075
        c-0.449,1.143-1.552,1.894-2.779,1.896c-0.841,0.03-1.648-0.327-2.19-0.969c-0.593-0.64-0.911-1.487-0.885-2.358
        c-0.018-0.871,0.3-1.714,0.885-2.359c0.564-0.607,1.361-0.944,2.19-0.927c1.27-0.054,2.415,0.762,2.779,1.979h3.075
        c-0.229-1.291-0.955-2.439-2.021-3.201c-1.103-0.834-2.451-1.279-3.833-1.264c-1.572-0.018-3.087,0.588-4.213,1.685
        c-1.155,1.09-1.784,2.625-1.727,4.212c-0.041,1.593,0.603,3.126,1.769,4.212c2.209,2.06,5.572,2.255,8.004,0.463 M442.582,340.25
        c-9.947-0.008-19.469,4.038-26.368,11.205c-13.954-14.66-37.15-15.233-51.811-1.279c-7.079,6.738-11.174,16.024-11.374,25.795l0,0
        v45.45h20.809V376.35c0.544-8.753,8.081-15.409,16.835-14.865c8.14,0.506,14.572,7.095,14.884,15.244v44.692h21.062v-45.492
        c0.614-8.749,8.205-15.343,16.954-14.729c8.198,0.576,14.602,7.312,14.764,15.529v44.692h20.809v-45.619l0,0
        c-0.524-19.867-16.772-35.704-36.646-35.72 M225.314,340.082c-19.874,0.016-36.121,15.853-36.646,35.72l0,0v25.274l-0.295,20.261
        h21.062v-45.198c0.163-8.747,7.386-15.706,16.133-15.543c8.747,0.163,15.706,7.386,15.543,16.133l0,0v44.692h21.062v-45.619l0,0
        c-0.503-19.876-16.764-35.727-36.646-35.72 M307.495,401.834c-11.144,0-20.177-9.033-20.177-20.177s9.033-20.177,20.177-20.177
        s20.177,9.033,20.177,20.177S318.639,401.834,307.495,401.834L307.495,401.834 M307.495,341.725
        c-22.077-0.028-39.997,17.847-40.024,39.924s17.848,39.997,39.925,40.024c7.228,0.009,14.322-1.941,20.529-5.645v5.729h19.418
        v-37.236c0-0.927,0-1.896,0-2.864C347.343,359.636,329.516,341.771,307.495,341.725"/>
        <path fill={mainColor} d="M163.52,342.483v44.776c0.572,8.309-5.7,15.508-14.009,16.079c-8.31,0.571-15.508-5.7-16.079-14.01
        c-0.034-0.492-0.044-0.986-0.029-1.48l0.758-45.282h-20.092l-0.338,45.535l0,0c-0.028,19.404,15.438,35.282,34.836,35.762
        c5.242,0,10.41-1.241,15.08-3.622v30.201h19.84v-107.96H163.52z"/>
        <path fill={mainColor} d="M577.459,400.612c-5.746,0-10.404,4.658-10.404,10.404s4.658,10.404,10.404,10.404s10.404-4.658,10.404-10.404
        C587.84,405.28,583.196,400.636,577.459,400.612"/>
        </g>
    </svg>
    );
};

export default LogoDds;