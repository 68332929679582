import React, {Component} from 'react';

class Newsletter extends Component {
    constructor(props) {
        super(props);
        this.handleNewsletter = this.handleNewsletter.bind(this);

    }

    handleNewsletter() {
        console.log('Newsletter window');
    };

    render() {

        const { newsletterLabel } = this.props;

        return (
            <div className="newsletter">
                <button onClick={()=>this.handleNewsletter}>
                    { newsletterLabel }
                </button>
            </div>
        );
    }
}

export default Newsletter;