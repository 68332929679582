import React from 'react';
import PlayerBack from '../../svg/PlayerBack';

const ScrollToTopButton = () => {
    return (
        <div className="scroll-to-top">
            <a href="#" title="Home">
                <PlayerBack mainColor="#000000" />
            </a>
        </div>
    );
};

export default ScrollToTopButton;