import React from 'react';

const Wave = ({mainColor}) => {

    return (
        <svg version="1.1" id="wave" xmlns="http://www.w3.org/2000/svg" width="105px" height="104.85px" viewBox="0 0 105 104.85" >
<g>
    <path fill={mainColor} d="M105,104.854V52.878C75.773,52.876,52.067,29.235,51.994-0.003H0C0.072,57.943,47.066,104.852,105,104.854"/>
</g>
</svg>
    );
};

export default Wave;