import React from 'react';

const LogoPs = () => {
	return (
		<svg version="1.1" id="Logo_DDS" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
		     width="114px" height="32px" viewBox="0 0 114 32" enableBackground="new 0 0 114 32">
			<title>Dynamo DS logo</title>
			<g>
				<path d="M104.793,11.917L104.793,11.917c0,2.294-1.859,4.154-4.154,4.154c-2.295-0.001-4.154-1.86-4.154-4.154
		c0-2.278,1.836-4.131,4.113-4.153V3.651c-4.589,0-8.309,3.719-8.309,8.308c0,4.588,3.72,8.309,8.309,8.309
		c4.588,0,8.307-3.721,8.307-8.309l0,0l0,0L104.793,11.917z M10.027,16.121v4.096c4.537-0.031,8.208-3.695,8.25-8.233l0,0v-12.08
		h-4.096v4.885c-3.973-2.294-9.054-0.933-11.349,3.04c-0.714,1.236-1.097,2.636-1.113,4.063h4.104
		c0.042-2.294,1.935-4.119,4.228-4.078c2.294,0.042,4.12,1.935,4.078,4.229c-0.041,2.241-1.853,4.047-4.094,4.078 M0.299,3.51
		C0.293,3.088,0.461,2.682,0.764,2.388c0.587-0.612,1.561-0.632,2.173-0.045c0.015,0.015,0.03,0.029,0.045,0.045
		c0.619,0.62,0.619,1.624,0,2.243c-0.289,0.3-0.689,0.465-1.105,0.457c-0.416,0.014-0.819-0.146-1.113-0.44
		c-0.302-0.294-0.47-0.699-0.465-1.121 M3.231,4.872c0.601-0.714,0.454-1.88,0-2.717c-0.738-0.747-1.942-0.756-2.69-0.018
		C0.535,2.144,0.529,2.149,0.523,2.155c-0.74,0.748-0.74,1.952,0,2.7C0.878,5.22,1.368,5.422,1.877,5.412
		c0.508,0.009,0.999-0.193,1.354-0.557 M2.633,4.415C2.84,4.259,2.986,4.036,3.049,3.784H2.442C2.354,4.01,2.136,4.157,1.894,4.158
		C1.729,4.164,1.569,4.094,1.462,3.967c-0.117-0.126-0.18-0.293-0.175-0.465C1.284,3.33,1.347,3.164,1.462,3.036
		C1.574,2.917,1.73,2.851,1.894,2.854c0.25-0.011,0.477,0.15,0.548,0.391h0.606C3.003,2.989,2.86,2.763,2.65,2.612
		C2.433,2.448,2.167,2.36,1.894,2.363C1.584,2.36,1.285,2.479,1.063,2.695c-0.228,0.216-0.352,0.519-0.34,0.831
		C0.714,3.841,0.842,4.143,1.072,4.357C1.507,4.764,2.17,4.802,2.65,4.448 M84.348,3.684c-1.961-0.001-3.84,0.797-5.201,2.21
		C76.395,3.003,71.82,2.89,68.93,5.642c-1.396,1.329-2.205,3.16-2.244,5.088l0,0v8.963h4.104v-8.889
		c0.106-1.727,1.594-3.039,3.319-2.932c1.605,0.1,2.875,1.399,2.937,3.007v8.814H81.2V10.72c0.12-1.726,1.618-3.025,3.344-2.904
		c1.617,0.113,2.88,1.441,2.912,3.063v8.814h4.104v-8.998l0,0c-0.104-3.919-3.309-7.042-7.229-7.045 M41.497,3.651
		c-3.919,0.003-7.124,3.126-7.228,7.045l0,0v4.984l-0.058,3.996h4.154v-8.914c0.032-1.726,1.457-3.098,3.182-3.065
		c1.725,0.031,3.098,1.456,3.065,3.182l0,0v8.814h4.153v-8.998l0,0c-0.099-3.921-3.306-7.047-7.227-7.045 M57.705,15.83
		c-2.198,0-3.979-1.781-3.979-3.979c0-2.198,1.781-3.979,3.979-3.979c2.197,0,3.979,1.781,3.979,3.979
		C61.684,14.049,59.902,15.83,57.705,15.83L57.705,15.83 M57.705,3.975c-4.354-0.005-7.889,3.521-7.895,7.874
		c-0.005,4.354,3.521,7.889,7.875,7.895c1.426,0.001,2.823-0.383,4.049-1.113v1.13h3.83v-7.345c0-0.183,0-0.374,0-0.564
		C65.564,7.508,62.048,3.984,57.705,3.975"/>
				<path d="M29.309,4.124v8.831c0.113,1.639-1.124,3.059-2.763,3.172c-1.639,0.112-3.059-1.125-3.171-2.764
		c-0.006-0.097-0.008-0.194-0.005-0.292l0.149-8.931h-3.963l-0.066,8.98l0,0c-0.006,3.827,3.044,6.959,6.871,7.054
		c1.034,0,2.053-0.244,2.974-0.715v5.957h3.913V4.124H29.309L29.309,4.124z"/>
				<path d="M110.949,15.589c-1.135,0-2.053,0.919-2.053,2.052c0,1.135,0.918,2.053,2.053,2.053c1.133,0,2.051-0.918,2.051-2.053
		C112.996,16.51,112.08,15.594,110.949,15.589"/>
			</g>
			<g>
				<path d="M34.671,30.877c-0.055,0-0.102-0.021-0.141-0.063c-0.039-0.043-0.058-0.094-0.058-0.153v-5.958
		c0-0.06,0.02-0.111,0.058-0.153s0.085-0.063,0.141-0.063h0.654c0.061,0,0.109,0.021,0.145,0.063
		c0.036,0.042,0.054,0.094,0.054,0.153v0.36c0.132-0.192,0.306-0.352,0.521-0.478s0.483-0.188,0.803-0.188
		c0.292,0,0.546,0.051,0.762,0.152c0.215,0.103,0.395,0.248,0.538,0.437c0.144,0.189,0.254,0.413,0.332,0.671
		c0.077,0.258,0.121,0.543,0.132,0.854c0.005,0.103,0.008,0.207,0.008,0.315c0,0.107-0.003,0.216-0.008,0.324
		c-0.006,0.3-0.047,0.577-0.124,0.832c-0.078,0.255-0.189,0.478-0.335,0.666c-0.146,0.189-0.327,0.338-0.542,0.445
		c-0.215,0.108-0.469,0.162-0.762,0.162c-0.298,0-0.552-0.059-0.762-0.176c-0.21-0.116-0.381-0.271-0.514-0.463v2.043
		c0,0.06-0.018,0.11-0.054,0.153c-0.036,0.041-0.084,0.063-0.145,0.063H34.671z M36.543,28.294c0.237,0,0.422-0.056,0.555-0.166
		c0.132-0.111,0.229-0.257,0.29-0.437c0.061-0.181,0.097-0.378,0.108-0.595c0.005-0.18,0.005-0.359,0-0.54
		c-0.011-0.216-0.047-0.414-0.108-0.594s-0.157-0.325-0.29-0.437c-0.133-0.11-0.318-0.166-0.555-0.166
		c-0.227,0-0.41,0.057-0.551,0.171s-0.243,0.26-0.307,0.437s-0.101,0.358-0.112,0.544c-0.005,0.108-0.008,0.223-0.008,0.342
		c0,0.12,0.002,0.237,0.008,0.352c0.006,0.18,0.044,0.353,0.116,0.518s0.177,0.302,0.314,0.409
		C36.143,28.24,36.322,28.294,36.543,28.294z"/>
				<path d="M39.747,29.167c-0.055,0-0.102-0.021-0.141-0.063c-0.039-0.041-0.058-0.093-0.058-0.152v-4.239
		c0-0.065,0.02-0.12,0.058-0.162s0.085-0.063,0.141-0.063h0.671c0.055,0,0.104,0.021,0.145,0.063
		c0.042,0.042,0.063,0.097,0.063,0.162v0.369c0.127-0.192,0.291-0.339,0.493-0.441c0.201-0.102,0.435-0.152,0.7-0.152h0.339
		c0.061,0,0.109,0.021,0.145,0.063c0.036,0.042,0.054,0.094,0.054,0.153v0.647c0,0.061-0.018,0.111-0.054,0.153
		c-0.036,0.042-0.084,0.063-0.145,0.063h-0.646c-0.265,0-0.471,0.079-0.617,0.238c-0.146,0.159-0.22,0.383-0.22,0.67v2.476
		c0,0.06-0.019,0.111-0.058,0.152c-0.039,0.043-0.088,0.063-0.149,0.063H39.747z"/>
				<path d="M44.848,29.257c-0.442,0-0.813-0.084-1.114-0.252s-0.53-0.405-0.687-0.711c-0.157-0.306-0.247-0.663-0.269-1.071
		c-0.005-0.107-0.008-0.239-0.008-0.396s0.003-0.288,0.008-0.396c0.022-0.414,0.115-0.772,0.277-1.075
		c0.162-0.303,0.395-0.538,0.695-0.706s0.667-0.252,1.098-0.252s0.796,0.084,1.097,0.252s0.533,0.403,0.695,0.706
		c0.163,0.303,0.256,0.661,0.277,1.075c0.006,0.108,0.009,0.24,0.009,0.396s-0.003,0.288-0.009,0.396
		c-0.021,0.408-0.111,0.766-0.269,1.071s-0.387,0.543-0.688,0.711S45.289,29.257,44.848,29.257z M44.848,28.366
		c0.292,0,0.519-0.101,0.679-0.302s0.248-0.496,0.265-0.887c0.006-0.09,0.009-0.207,0.009-0.351c0-0.145-0.003-0.261-0.009-0.352
		c-0.017-0.39-0.104-0.685-0.265-0.886s-0.387-0.302-0.679-0.302c-0.293,0-0.52,0.101-0.68,0.302s-0.248,0.496-0.265,0.886
		c-0.006,0.091-0.008,0.207-0.008,0.352c0,0.144,0.002,0.261,0.008,0.351c0.017,0.391,0.104,0.686,0.265,0.887
		S44.555,28.366,44.848,28.366z"/>
				<path d="M49.443,29.257c-0.287,0-0.54-0.053-0.758-0.157c-0.218-0.105-0.398-0.254-0.542-0.445
		c-0.144-0.192-0.254-0.419-0.331-0.68c-0.078-0.261-0.122-0.548-0.133-0.859c-0.006-0.108-0.008-0.206-0.008-0.293
		s0.002-0.185,0.008-0.292c0.011-0.3,0.054-0.579,0.128-0.837c0.075-0.258,0.186-0.484,0.332-0.68
		c0.146-0.195,0.328-0.347,0.546-0.455c0.218-0.107,0.471-0.161,0.758-0.161c0.298,0,0.551,0.055,0.758,0.166
		s0.379,0.254,0.518,0.428v-1.998c0-0.061,0.018-0.111,0.054-0.153s0.084-0.063,0.145-0.063h0.704c0.055,0,0.102,0.021,0.141,0.063
		s0.058,0.093,0.058,0.153v5.958c0,0.06-0.019,0.111-0.058,0.152c-0.039,0.043-0.086,0.063-0.141,0.063h-0.654
		c-0.061,0-0.109-0.021-0.145-0.063c-0.036-0.041-0.054-0.093-0.054-0.152v-0.333c-0.139,0.186-0.313,0.339-0.526,0.459
		C50.03,29.197,49.764,29.257,49.443,29.257z M49.75,28.294c0.231,0,0.416-0.057,0.555-0.171c0.138-0.114,0.24-0.26,0.306-0.437
		c0.066-0.177,0.103-0.358,0.108-0.545c0.005-0.107,0.008-0.223,0.008-0.346s-0.003-0.239-0.008-0.347
		c-0.006-0.181-0.045-0.353-0.116-0.518c-0.072-0.165-0.177-0.302-0.314-0.409c-0.139-0.108-0.317-0.162-0.538-0.162
		c-0.232,0-0.416,0.056-0.551,0.166c-0.136,0.111-0.233,0.257-0.294,0.437s-0.097,0.378-0.107,0.594c-0.006,0.181-0.006,0.36,0,0.54
		c0.011,0.217,0.047,0.414,0.107,0.595c0.061,0.18,0.158,0.325,0.294,0.437C49.334,28.238,49.518,28.294,49.75,28.294z"/>
				<path d="M54.519,29.257c-0.337,0-0.628-0.082-0.874-0.247c-0.245-0.165-0.435-0.399-0.566-0.702
		c-0.133-0.303-0.199-0.662-0.199-1.075v-2.529c0-0.06,0.02-0.111,0.059-0.153c0.038-0.042,0.085-0.063,0.141-0.063h0.729
		c0.061,0,0.108,0.021,0.145,0.063s0.054,0.094,0.054,0.153v2.475c0,0.744,0.301,1.116,0.902,1.116c0.287,0,0.515-0.099,0.684-0.297
		c0.168-0.198,0.253-0.471,0.253-0.819v-2.475c0-0.06,0.019-0.111,0.058-0.153c0.038-0.042,0.086-0.063,0.141-0.063h0.729
		c0.056,0,0.103,0.021,0.141,0.063c0.039,0.042,0.059,0.094,0.059,0.153v4.248c0,0.06-0.02,0.111-0.059,0.152
		c-0.038,0.043-0.085,0.063-0.141,0.063H56.1c-0.061,0-0.108-0.021-0.145-0.063c-0.036-0.041-0.054-0.093-0.054-0.152v-0.369
		c-0.149,0.21-0.334,0.375-0.555,0.495S54.85,29.257,54.519,29.257z"/>
				<path d="M59.925,29.257c-0.397,0-0.746-0.081-1.047-0.243s-0.536-0.397-0.704-0.706s-0.261-0.683-0.277-1.121
		c-0.006-0.09-0.008-0.208-0.008-0.355c0-0.146,0.002-0.268,0.008-0.364c0.017-0.438,0.109-0.812,0.277-1.12
		c0.168-0.31,0.403-0.545,0.704-0.707s0.649-0.242,1.047-0.242c0.354,0,0.654,0.052,0.902,0.157c0.249,0.104,0.453,0.24,0.613,0.405
		s0.28,0.336,0.36,0.513s0.122,0.337,0.128,0.481c0.006,0.066-0.013,0.118-0.054,0.157s-0.09,0.059-0.145,0.059h-0.729
		c-0.056,0-0.099-0.015-0.129-0.045s-0.06-0.075-0.087-0.135c-0.083-0.24-0.191-0.41-0.327-0.509
		c-0.135-0.099-0.308-0.148-0.518-0.148c-0.275,0-0.495,0.095-0.658,0.283c-0.163,0.189-0.25,0.488-0.261,0.896
		c-0.011,0.229-0.011,0.438,0,0.63c0.011,0.414,0.098,0.715,0.261,0.9c0.163,0.187,0.383,0.279,0.658,0.279
		c0.216,0,0.39-0.05,0.521-0.148c0.133-0.1,0.24-0.269,0.323-0.509c0.027-0.06,0.057-0.104,0.087-0.135s0.073-0.045,0.129-0.045
		h0.729c0.055,0,0.104,0.02,0.145,0.059s0.06,0.091,0.054,0.157c-0.006,0.107-0.031,0.229-0.078,0.364s-0.122,0.273-0.224,0.414
		c-0.103,0.141-0.23,0.27-0.386,0.387c-0.154,0.117-0.341,0.212-0.559,0.284C60.465,29.221,60.212,29.257,59.925,29.257z"/>
				<path d="M64.735,29.167c-0.331,0-0.61-0.063-0.836-0.189c-0.227-0.126-0.395-0.313-0.505-0.563
		c-0.111-0.248-0.166-0.559-0.166-0.931v-2.043h-0.654c-0.055,0-0.103-0.021-0.141-0.063c-0.039-0.042-0.058-0.096-0.058-0.162
		v-0.513c0-0.06,0.019-0.111,0.058-0.153c0.038-0.042,0.086-0.063,0.141-0.063h0.654v-1.494c0-0.061,0.018-0.111,0.054-0.153
		s0.084-0.063,0.145-0.063h0.671c0.056,0,0.103,0.021,0.141,0.063c0.039,0.042,0.059,0.093,0.059,0.153v1.494h1.035
		c0.055,0,0.102,0.021,0.141,0.063c0.038,0.042,0.058,0.094,0.058,0.153v0.513c0,0.066-0.02,0.12-0.058,0.162
		c-0.039,0.042-0.086,0.063-0.141,0.063h-1.035v1.952c0,0.246,0.04,0.438,0.12,0.576c0.08,0.139,0.219,0.207,0.418,0.207h0.571
		c0.055,0,0.102,0.021,0.141,0.063c0.038,0.042,0.058,0.093,0.058,0.152v0.559c0,0.06-0.02,0.111-0.058,0.152
		c-0.039,0.043-0.086,0.063-0.141,0.063H64.735z"/>
				<path d="M66.508,23.713c-0.055,0-0.102-0.021-0.141-0.063s-0.058-0.094-0.058-0.153v-0.648c0-0.06,0.019-0.11,0.058-0.152
		s0.086-0.063,0.141-0.063h0.754c0.061,0,0.11,0.021,0.148,0.063c0.039,0.042,0.059,0.093,0.059,0.152v0.648
		c0,0.06-0.02,0.111-0.059,0.153c-0.038,0.042-0.088,0.063-0.148,0.063H66.508z M66.541,29.167c-0.055,0-0.103-0.021-0.141-0.063
		c-0.039-0.041-0.058-0.093-0.058-0.152v-4.248c0-0.06,0.019-0.111,0.058-0.153c0.038-0.042,0.086-0.063,0.141-0.063h0.688
		c0.061,0,0.108,0.021,0.145,0.063s0.054,0.094,0.054,0.153v4.248c0,0.06-0.018,0.111-0.054,0.152
		c-0.036,0.043-0.084,0.063-0.145,0.063H66.541z"/>
				<path d="M70.433,29.257c-0.442,0-0.813-0.084-1.114-0.252s-0.529-0.405-0.687-0.711c-0.158-0.306-0.247-0.663-0.27-1.071
		c-0.006-0.107-0.008-0.239-0.008-0.396s0.002-0.288,0.008-0.396c0.022-0.414,0.114-0.772,0.277-1.075s0.395-0.538,0.695-0.706
		s0.667-0.252,1.098-0.252s0.796,0.084,1.097,0.252s0.533,0.403,0.695,0.706c0.163,0.303,0.256,0.661,0.277,1.075
		c0.006,0.108,0.009,0.24,0.009,0.396s-0.003,0.288-0.009,0.396c-0.021,0.408-0.111,0.766-0.269,1.071s-0.387,0.543-0.688,0.711
		S70.874,29.257,70.433,29.257z M70.433,28.366c0.292,0,0.519-0.101,0.679-0.302s0.248-0.496,0.265-0.887
		c0.006-0.09,0.009-0.207,0.009-0.351c0-0.145-0.003-0.261-0.009-0.352c-0.017-0.39-0.104-0.685-0.265-0.886
		s-0.387-0.302-0.679-0.302c-0.293,0-0.52,0.101-0.68,0.302s-0.248,0.496-0.265,0.886c-0.006,0.091-0.008,0.207-0.008,0.352
		c0,0.144,0.002,0.261,0.008,0.351c0.017,0.391,0.104,0.686,0.265,0.887S70.14,28.366,70.433,28.366z"/>
				<path d="M73.628,29.167c-0.055,0-0.103-0.021-0.141-0.063c-0.039-0.041-0.058-0.093-0.058-0.152v-4.248
		c0-0.06,0.019-0.111,0.058-0.153c0.038-0.042,0.086-0.063,0.141-0.063h0.679c0.056,0,0.103,0.021,0.141,0.063
		c0.039,0.042,0.059,0.094,0.059,0.153v0.369c0.144-0.192,0.327-0.353,0.551-0.481s0.501-0.193,0.832-0.193
		c0.336,0,0.629,0.082,0.878,0.247c0.248,0.165,0.439,0.397,0.575,0.697c0.135,0.3,0.203,0.66,0.203,1.08v2.529
		c0,0.06-0.02,0.111-0.059,0.152c-0.038,0.043-0.086,0.063-0.141,0.063h-0.729c-0.056,0-0.103-0.021-0.141-0.063
		c-0.039-0.041-0.059-0.093-0.059-0.152v-2.476c0-0.348-0.078-0.62-0.235-0.818s-0.385-0.297-0.684-0.297
		c-0.287,0-0.516,0.099-0.687,0.297c-0.172,0.198-0.257,0.471-0.257,0.818v2.476c0,0.06-0.02,0.111-0.059,0.152
		c-0.038,0.043-0.085,0.063-0.141,0.063H73.628z"/>
				<path d="M82.124,29.257c-0.331,0-0.614-0.042-0.849-0.126s-0.427-0.188-0.575-0.314c-0.149-0.126-0.26-0.252-0.332-0.378
		c-0.071-0.127-0.11-0.231-0.115-0.315c-0.006-0.066,0.012-0.118,0.054-0.157c0.041-0.039,0.087-0.059,0.137-0.059h0.654
		c0.021,0,0.041,0.003,0.058,0.009s0.038,0.024,0.066,0.054c0.071,0.066,0.147,0.136,0.228,0.207
		c0.08,0.072,0.175,0.134,0.285,0.185s0.249,0.077,0.414,0.077c0.216,0,0.396-0.047,0.543-0.14c0.146-0.094,0.219-0.227,0.219-0.4
		c0-0.126-0.031-0.229-0.095-0.307s-0.181-0.148-0.352-0.211c-0.172-0.063-0.414-0.131-0.729-0.203
		c-0.309-0.078-0.565-0.177-0.77-0.297s-0.356-0.27-0.455-0.45c-0.1-0.18-0.149-0.39-0.149-0.63c0-0.233,0.064-0.457,0.194-0.67
		s0.323-0.389,0.58-0.527c0.257-0.138,0.575-0.206,0.956-0.206c0.298,0,0.558,0.04,0.778,0.121s0.404,0.182,0.551,0.302
		s0.258,0.241,0.335,0.364s0.119,0.229,0.124,0.319c0.006,0.066-0.011,0.118-0.049,0.157c-0.039,0.039-0.083,0.059-0.133,0.059
		h-0.613c-0.027,0-0.052-0.006-0.074-0.018s-0.044-0.027-0.066-0.045c-0.061-0.061-0.127-0.125-0.198-0.193
		c-0.072-0.069-0.159-0.128-0.261-0.176c-0.103-0.048-0.236-0.072-0.401-0.072c-0.21,0-0.37,0.05-0.48,0.148
		c-0.11,0.1-0.166,0.224-0.166,0.374c0,0.102,0.026,0.19,0.079,0.266c0.052,0.074,0.158,0.146,0.318,0.211
		c0.16,0.066,0.403,0.135,0.729,0.207c0.37,0.072,0.664,0.179,0.882,0.319c0.218,0.142,0.373,0.304,0.464,0.486
		s0.137,0.386,0.137,0.607c0,0.271-0.073,0.513-0.22,0.729c-0.146,0.216-0.361,0.385-0.646,0.508S82.543,29.257,82.124,29.257z"/>
				<path d="M86.752,29.257c-0.613,0-1.099-0.191-1.457-0.576c-0.359-0.384-0.553-0.93-0.58-1.638c-0.006-0.06-0.008-0.137-0.008-0.229
		s0.002-0.166,0.008-0.221c0.022-0.456,0.117-0.848,0.286-1.174c0.168-0.327,0.401-0.579,0.699-0.757
		c0.298-0.177,0.648-0.265,1.052-0.265c0.447,0,0.822,0.1,1.126,0.301s0.532,0.48,0.688,0.837c0.154,0.357,0.231,0.77,0.231,1.238
		v0.188c0,0.061-0.02,0.111-0.058,0.153c-0.039,0.042-0.089,0.063-0.149,0.063h-2.749c0,0.036,0,0.061,0,0.072
		c0.006,0.21,0.043,0.403,0.112,0.58c0.068,0.178,0.171,0.32,0.306,0.428c0.136,0.108,0.297,0.162,0.484,0.162
		c0.155,0,0.284-0.025,0.39-0.076c0.104-0.051,0.19-0.109,0.257-0.176s0.112-0.12,0.141-0.162c0.05-0.072,0.09-0.115,0.12-0.13
		c0.03-0.016,0.075-0.023,0.137-0.023h0.712c0.055,0,0.101,0.019,0.137,0.055c0.035,0.036,0.051,0.081,0.045,0.135
		c-0.005,0.096-0.051,0.213-0.137,0.351c-0.085,0.139-0.208,0.273-0.368,0.405s-0.36,0.242-0.601,0.329
		C87.336,29.214,87.061,29.257,86.752,29.257z M85.841,26.422h1.83v-0.027c0-0.233-0.036-0.439-0.107-0.616
		c-0.072-0.177-0.177-0.314-0.314-0.414c-0.139-0.099-0.304-0.148-0.497-0.148s-0.359,0.05-0.497,0.148
		c-0.138,0.1-0.241,0.237-0.311,0.414s-0.104,0.383-0.104,0.616V26.422z"/>
				<path d="M89.873,29.167c-0.055,0-0.103-0.021-0.141-0.063c-0.039-0.041-0.058-0.093-0.058-0.152v-4.239
		c0-0.065,0.019-0.12,0.058-0.162c0.038-0.042,0.086-0.063,0.141-0.063h0.671c0.055,0,0.104,0.021,0.145,0.063
		c0.042,0.042,0.063,0.097,0.063,0.162v0.369c0.127-0.192,0.291-0.339,0.492-0.441c0.202-0.102,0.435-0.152,0.7-0.152h0.339
		c0.062,0,0.109,0.021,0.146,0.063c0.035,0.042,0.054,0.094,0.054,0.153v0.647c0,0.061-0.019,0.111-0.054,0.153
		c-0.036,0.042-0.084,0.063-0.146,0.063h-0.646c-0.265,0-0.471,0.079-0.617,0.238c-0.146,0.159-0.219,0.383-0.219,0.67v2.476
		c0,0.06-0.02,0.111-0.059,0.152c-0.038,0.043-0.088,0.063-0.148,0.063H89.873z"/>
				<path d="M94.651,29.167c-0.089,0-0.153-0.022-0.195-0.067c-0.041-0.045-0.075-0.098-0.104-0.157l-1.548-4.177
		c-0.011-0.029-0.017-0.057-0.017-0.081c0-0.054,0.018-0.1,0.054-0.139s0.079-0.059,0.128-0.059h0.671
		c0.066,0,0.116,0.02,0.149,0.059s0.055,0.076,0.066,0.112l1.101,3.159l1.102-3.159c0.011-0.036,0.033-0.073,0.066-0.112
		s0.083-0.059,0.149-0.059h0.679c0.044,0,0.084,0.02,0.12,0.059c0.035,0.039,0.054,0.085,0.054,0.139
		c0,0.024-0.003,0.052-0.009,0.081l-1.557,4.177c-0.021,0.06-0.055,0.112-0.099,0.157c-0.045,0.045-0.111,0.067-0.199,0.067H94.651z
		"/>
				<path d="M98.046,23.713c-0.055,0-0.102-0.021-0.141-0.063s-0.058-0.094-0.058-0.153v-0.648c0-0.06,0.019-0.11,0.058-0.152
		s0.086-0.063,0.141-0.063H98.8c0.061,0,0.11,0.021,0.148,0.063c0.039,0.042,0.059,0.093,0.059,0.152v0.648
		c0,0.06-0.02,0.111-0.059,0.153c-0.038,0.042-0.088,0.063-0.148,0.063H98.046z M98.079,29.167c-0.055,0-0.103-0.021-0.141-0.063
		c-0.039-0.041-0.058-0.093-0.058-0.152v-4.248c0-0.06,0.019-0.111,0.058-0.153c0.038-0.042,0.086-0.063,0.141-0.063h0.688
		c0.061,0,0.108,0.021,0.145,0.063s0.054,0.094,0.054,0.153v4.248c0,0.06-0.018,0.111-0.054,0.152
		c-0.036,0.043-0.084,0.063-0.145,0.063H98.079z"/>
				<path d="M101.929,29.257c-0.397,0-0.746-0.081-1.047-0.243s-0.536-0.397-0.704-0.706s-0.261-0.683-0.277-1.121
		c-0.006-0.09-0.008-0.208-0.008-0.355c0-0.146,0.002-0.268,0.008-0.364c0.017-0.438,0.109-0.812,0.277-1.12
		c0.168-0.31,0.403-0.545,0.704-0.707s0.649-0.242,1.047-0.242c0.354,0,0.654,0.052,0.902,0.157c0.249,0.104,0.453,0.24,0.613,0.405
		s0.28,0.336,0.36,0.513s0.122,0.337,0.128,0.481c0.006,0.066-0.013,0.118-0.054,0.157s-0.09,0.059-0.145,0.059h-0.729
		c-0.056,0-0.099-0.015-0.129-0.045s-0.06-0.075-0.087-0.135c-0.083-0.24-0.191-0.41-0.327-0.509
		c-0.135-0.099-0.308-0.148-0.518-0.148c-0.275,0-0.495,0.095-0.658,0.283c-0.163,0.189-0.25,0.488-0.261,0.896
		c-0.011,0.229-0.011,0.438,0,0.63c0.011,0.414,0.098,0.715,0.261,0.9c0.163,0.187,0.383,0.279,0.658,0.279
		c0.216,0,0.39-0.05,0.521-0.148c0.133-0.1,0.24-0.269,0.323-0.509c0.027-0.06,0.057-0.104,0.087-0.135s0.073-0.045,0.129-0.045
		h0.729c0.055,0,0.104,0.02,0.145,0.059s0.06,0.091,0.054,0.157c-0.006,0.107-0.031,0.229-0.078,0.364s-0.122,0.273-0.224,0.414
		c-0.103,0.141-0.23,0.27-0.386,0.387c-0.154,0.117-0.341,0.212-0.559,0.284C102.469,29.221,102.216,29.257,101.929,29.257z"/>
				<path d="M106.648,29.257c-0.613,0-1.099-0.191-1.457-0.576c-0.359-0.384-0.553-0.93-0.58-1.638
		c-0.006-0.06-0.008-0.137-0.008-0.229s0.002-0.166,0.008-0.221c0.022-0.456,0.117-0.848,0.286-1.174
		c0.168-0.327,0.401-0.579,0.699-0.757c0.298-0.177,0.648-0.265,1.052-0.265c0.447,0,0.822,0.1,1.126,0.301s0.532,0.48,0.688,0.837
		c0.154,0.357,0.231,0.77,0.231,1.238v0.188c0,0.061-0.02,0.111-0.058,0.153c-0.039,0.042-0.089,0.063-0.149,0.063h-2.749
		c0,0.036,0,0.061,0,0.072c0.006,0.21,0.043,0.403,0.112,0.58c0.068,0.178,0.171,0.32,0.306,0.428
		c0.136,0.108,0.297,0.162,0.484,0.162c0.155,0,0.284-0.025,0.39-0.076c0.104-0.051,0.19-0.109,0.257-0.176s0.112-0.12,0.141-0.162
		c0.05-0.072,0.09-0.115,0.12-0.13c0.03-0.016,0.075-0.023,0.137-0.023h0.712c0.055,0,0.101,0.019,0.137,0.055
		c0.035,0.036,0.051,0.081,0.045,0.135c-0.005,0.096-0.051,0.213-0.137,0.351c-0.085,0.139-0.208,0.273-0.368,0.405
		s-0.36,0.242-0.601,0.329C107.232,29.214,106.957,29.257,106.648,29.257z M105.737,26.422h1.83v-0.027
		c0-0.233-0.036-0.439-0.107-0.616c-0.072-0.177-0.177-0.314-0.314-0.414c-0.139-0.099-0.304-0.148-0.497-0.148
		s-0.359,0.05-0.497,0.148c-0.138,0.1-0.241,0.237-0.311,0.414s-0.104,0.383-0.104,0.616V26.422z"/>
			</g>
		</svg>

	);
};

export default LogoPs;