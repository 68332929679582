import React, {Component,Fragment} from 'react';
import axios from "axios";

//components
import SocialMediaIcon from "./SocialMediaIcon";

//config
import config from "../../config";
import {getDataById} from"../../tools/getDataById";

class SocialMedia extends Component {

    constructor(props) {
        super(props);
        this.state = {
            socialMediaList:[]
        }
    }

    componentDidMount() {

        const url = config.domainRelPath;
        axios
            .get(
                url+'/wp-json/acf/v3/pages/?per_page=30'
            )
            .then(res => {
                this.setState({
                    socialMediaList: getDataById(res.data, 5).social_media
                });
            })
            .catch(error => console.log(error));
    }


    render() {

        return (

            <Fragment>
            {
                <ul className="social-media">
                    {this.state.socialMediaList
                        .map((item,index) =>
                            <li key={index}>
                                <a href={item.link}
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   title={item.nazwa}>
                                    <SocialMediaIcon
                                        tag={item.nazwa}
                                        mainColor={this.props.iconColor} />
                                </a>
                            </li>
                        )}
                </ul>
            }

            </Fragment>
        );
    }
};

export default SocialMedia;