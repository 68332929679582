import React,{ Component } from 'react';

//svg
import PlayerPlay from '../svg/PlayerPlay';
import PlayerPause from '../svg/PlayerPause';

//tools
import Vimeo from '@u-wave/react-vimeo';
import MediaQuery from 'react-responsive';



class Player extends Component {

    constructor(props) {
        super(props);

        this.state = {
            mobilePaused: true,
            singlePaused: false,
        };

        this.handlePlayerPause = this.handlePlayerPause.bind(this);
        this.handlePlayerPlay = this.handlePlayerPlay.bind(this);
    }


    handlePlayerPlay() {
        this.setState({
            mobilePaused: false,
            singlePaused:false
        });
    }

    handlePlayerPause() {
        this.setState({
            mobilePaused: true,
            singlePaused: true
        });
    }





    render() {
        const vimeoId = this.props.video.vimeoId;
        const tytulFilmu = this.props.video.tytulFilmu;
        const klient = this.props.video.klient;
        const agencja = this.props.video.agencja;
        const rezyser = this.props.video.rezyser;
        const operator = this.props.video.operator;
        const panorama = this.props.video.panorama;

        const { mobilePaused, singlePaused } = this.state;




        return (
            <div className={ panorama ? 'player-container panoramic' : 'player-container' }
                data-theme={this.props.theme}>

                {singlePaused &&
                    <header>
                        <h4>{tytulFilmu}</h4>
                        <ul>
                            { klient && <li>Klient: {klient}</li> }
                            { agencja && <li>Agencja: {agencja}</li> }
                            { rezyser && <li>Reżyser: {rezyser}</li> }
                            { operator && <li>Operator: {operator}</li> }
                        </ul>
                    </header>
                }

                <nav>
                    <ul>
                        <li className="pause"
                            onClick={this.handlePlayerPause} >
                            <PlayerPause />
                        </li>
                        <li className="play"
                        onClick={this.handlePlayerPlay}>
                            <PlayerPlay />
                        </li>
                    </ul>

                </nav>



                    <MediaQuery maxWidth={768}>
                        <div className="stage mobile">
                            <Vimeo
                                video={vimeoId}
                                paused={mobilePaused}
                                showByline={false}
                                showTitle={false}
                                className="vid"
                                autoplay={false}
                                autopause={true}
                            />
                        </div>
                    </MediaQuery>

                    <MediaQuery minWidth={769}>
                        <div className="stage">
                            <Vimeo
                                video={vimeoId}
                                paused={singlePaused}
                                showByline={false}
                                showTitle={false}
                                className="vid"
                                autoplay={true}
                                autopause={false}
                            />
                        </div>
                </MediaQuery>


            </div>
        );
    }


};

export default Player;