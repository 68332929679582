import React, {useEffect,useState,Fragment} from 'react';
import {connect} from "react-redux";
import axios from "axios";

// actions
import { toggleOverlay } from "../../../actions/pageActions";
import {togglePlayer} from "../../../actions/playerActions";

// svg
import ScrollerLabel from "../../svg/ScrollerLabel";
import PlayerBack from '../../svg/PlayerBack';

// components
import PageSmoothScroller from './DpSmoothScroller';
import MenuDpTrigger from "../../Menu/MenuTopDp/MenuDpTrigger";
import MenuTopDp from '../../Menu/MenuTopDp';
import Player from '../../Player';
import PlayerIOS from '../../PlayerIOS';

import DpAboutUs from "./Onas";
import DpPortfolio from "../../PortfolioDp";
import DpKontakt from "./Kontakt";

// tools
import {isIOS} from "react-device-detect";
import {InView} from "react-intersection-observer";

//config
import config from "../../../config";
import {getDataById} from"../../../tools/getDataById";

const DynamoPost = (
    {
        articleContainerRef,
        showOverlay,
        openPlayer,
        playerOpened,        
        translate,
        selectedVideo
    }) => {

    const [menu,setMenu] = useState([])
    
    const [page,setPage] = useState({
        commonData: {},
        oNas: {},
        portfolio: {},
        kontakt:{},
        dpmenu:[],
        languages: [
            {
                name: "PL",
                code: "pl",
                url:"https://dynamofilm.pl",
                active: true
            },
            {
                name: "EN",
                code: "en",
                url:"http://en.dynamofilm.pl",
                active: false
            }
        ],
        locale:[]
    });
    
    useEffect( () => {
        const url = config.domainRelPath;

        axios
            .get(
                url+'/wp-json/api/dpmenu/?per_page=30'
            )
            .then(res => {
                setMenu(res.data);
            })
            .catch(error => console.log(error));

        axios
            .get(
                url+'/wp-json/acf/v3/pages/'
            )
            .then(res => {
                setPage({
                    ...page,
                    oNas: getDataById(res.data, 861),
                    kontakt: getDataById(res.data, 859),
                    portfolio: getDataById(res.data, 857),
                    commonData: getDataById(res.data, 855)
                });
                showOverlay(false);
            })
            .catch(error => console.log(error));

        window.scrollTo(0,0)

    },[
        setMenu,
        setPage,
        showOverlay 
    ]);

    return (
        <Fragment>
            <MenuTopDp menu={menu}
                       languages={page.languages} />
            { playerOpened && (
                <Fragment>

                    {isIOS ?
                        <PlayerIOS video={ selectedVideo } /> :
                        <Player video={ selectedVideo } />
                    }
                    <nav>
                        <ul>
                            <li className="home">
                                <a href={config.domain} target="_top">
                                    <ScrollerLabel mainColor="#E4E2E0" />
                                </a>
                            </li>
                            <li className="close-player" onClick={openPlayer}>
                                <PlayerBack mainColor="#E4E2E0" />
                            </li>
                        </ul>
                    </nav>
                </Fragment>
            )
            }
            <PageSmoothScroller>
                <article
                    ref={articleContainerRef}
                    className="content dp-content"
                    style={{ transform: 'matrix3d(1, 0.000174533, 0, 0, -0.000174533, 1, 0, 0, 0, 0, 1, 0, 0, -'+translate+', 0, 1)' }}>
                    <InView>
                        {({ inView, ref }) => (
                            <div  ref={ref}
                                  className="menu-trigger">
                                <MenuDpTrigger expanded={inView} />
                            </div>
                        )}
                    </InView>

                    <InView>
                        {({ ref }) => (
                            <div ref={ref}>
                                <DpAboutUs
                                    id={page.oNas.id}
                                    mobileId={page.oNas.idmobile}
                                    title={page.oNas.tytul}
                                    accentColor={page.commonData.accent_color}
                                    text={page.oNas.opis}
                                />
                            </div>
                        )}
                    </InView>


                    <DpPortfolio
                        id={page.portfolio.id}
                        mobileId={page.portfolio.idmobile}
                        accentColorRGBA={page.commonData.accent_color_rgba}
                        clips={page.portfolio.portfolioProdukcje}
                        locale={{
                            agencja: page.portfolio.agencja,
                            klient: page.portfolio.klient,
                            wiecej: page.portfolio.tekst_linku
                        }}
                        transpBg={page.portfolio.tlo_transp}
                        transpBgFull={page.portfolio.tlo_transp_panorama}
                        home
                    />

                    <DpKontakt
                        data={page.kontakt}
                    />

                </article>
            </PageSmoothScroller>

        </Fragment>
    );
};


const mapStateToProps =(state) => ({
    translate: state.page.calculatedScroll,
    playerOpened: state.player.playerOpened,
    selectedVideo: state.player.selectedVideo
});

const mapDispatchToProps = dispatch => ({
    openPlayer: () => dispatch(togglePlayer()),
    showOverlay: value => dispatch(toggleOverlay(value))
});

export default connect(mapStateToProps,mapDispatchToProps)(DynamoPost);