import React from 'react';

const ScrollerLabel = ({mainColor}) => {

    return (
        <svg version='1.1' id='scroller_label' xmlns='http://www.w3.org/2000/svg' x='0px' y="0px"
             width="18px" height="22.128px" viewBox="118.612 421.667 394.019 319.7">
<title>scroll</title>
            <path fill={mainColor} d="M198.894,581.985h-79.282c0.319,87.8,71.349,158.933,159.148,159.382l0,0h233.87v-79.516h-94.483
	c14.381-24.41,21.934-52.237,21.866-80.568c-0.319-87.93-71.453-159.167-159.381-159.616l0,79.282
	c44.622,0.585,80.32,37.232,79.735,81.854c-0.585,44.621-37.232,80.32-81.854,79.735c-43.797-0.574-79.161-35.939-79.735-79.735"/>
        </svg>
    );
};

export default ScrollerLabel;