import React,{Component} from 'react';
import {connect} from "react-redux";


// svg
import Falka from "../../../svg/Falka";

// tools
import MediaQuery from 'react-responsive';
import { InView } from 'react-intersection-observer';


class ONas extends Component {

    render(){
        const onas = this.props.oNas,
            id = onas.id,
            idmobile = onas.idmobile,
            slogan = onas.slogan,
            slogantransp = onas.slogan_transp,
            obraz_sloganu = onas.obraz_sloganu,
            opis = onas.opis,
            fota = onas.fota;
        const opisHtml = {__html: opis};
        const imgBg = {backgroundImage:'url('+obraz_sloganu+')'};

        return (

            <section className="section o-nas"
                     data-id-mobile={idmobile}>
                <div className="o-nas-fluid
                            container-fluid
                            page-content_wrapper"
                     id={id}>
                    <div className="row">
                        <div className="col col-xs-offset-1 col-xs-10 col-lg-5">
                            <div className="container-fluid introduction_wrapper">
                                <div className="row">
                                    <figure style={imgBg} title={slogan}>
                                        <img src={slogantransp} alt=""/>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col col-xs-offset-1 col-sm-offset-2 col-xs-10 col-sm-4">
                            <div className=" container-fluid
                             page-text-content
                             position-relative">
                                <div className="title-list">
                                    <p className="overtitle" dangerouslySetInnerHTML={opisHtml}></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <MediaQuery minWidth={768}>
                        <div className="row">
                            <div className="col col-md-offset-8 col-md-1">
                                <div className=" container-fluid
                                     page-text-divider">
                                    <div className="row">
                                        <InView>
                                            {({ inView, ref }) => (
                                                <div  ref={ref}
                                                      className={ inView ? 'falka-animate-backwards rotate' : 'falka-animate'}>
                                                    <Falka mainColor="#000000"/>
                                                </div>
                                            )}
                                        </InView>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </MediaQuery>
                </div>
                <figure id="texture-1"
                        className="image-opener
                                page-block
                                texture-1" >
                    <div className="mask">
                        <div className="js-animate"
                             style={{
                                 backgroundImage:'url('+fota+')',
                                 transform: 'translate3d(0,' + ( -30 + (this.props.translate * .3) )+'px,0)'
                             }} >&nbsp;</div>
                    </div>
                </figure>


            </section>

        )
    }


};

const mapStateToProps = state => ({
    translate: state.page.calculatedScroll
});

export default connect(mapStateToProps,null)(ONas);