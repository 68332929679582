import React, {Component,Fragment} from 'react';
import ReactDOM from 'react-dom';
import {connect} from "react-redux";

//tools
import { InView } from 'react-intersection-observer';
import MediaQuery from 'react-responsive';

class AnimatedPortfolioTextItem extends Component {
    constructor(props) {
        super(props);
        this.handleTxtPositionY = this.handleTxtPositionY.bind(this);
    }

    handleTxtPositionY(){
        const rect = ReactDOM.findDOMNode(this).getBoundingClientRect().top;
        return {
            transform: 'translate3d(0px, ' + (rect/4) + 'px, 0px)',
            display: 'block'
        }
    }

    render() {
        const {
            title,
            subtitle,
            year,
            labelClient, itemClient,
            labelAgency, itemAgency,
            labelDirector, itemDirector,
            labelOperator, itemOperator
        } = this.props;
        return (
            <Fragment>
                <MediaQuery maxWidth={768}>
                    <figcaption>
                        <div className="mobile-captions">
                            <h3>{title}</h3>
                            {/*<h4 className="mobile">{subtitle} / {year}</h4>
                            <div className="details">
                                { labelClient && <p>{labelClient}: {itemClient}</p> }
                                { labelAgency && <p>{labelAgency}: {itemAgency}</p> }
                            </div>*/}
                        </div>
                    </figcaption>
                </MediaQuery>
                <MediaQuery minWidth={769}>
                    <InView>
                        {({ inView, ref }) => (
                            <figcaption
                                data-page-scroll={this.props.translate}
                                ref={ref} >
                                { inView &&
                                    <div style={ this.handleTxtPositionY() } >
                                        <h3>
                                            <span>{title}</span>
                                        </h3>
                                        <h4 className="mobile">
                                            <span>
                                                {subtitle} / {year}
                                            </span>
                                        </h4>
                                        { itemClient && <p>{labelClient}: {itemClient}</p> }
                                        { itemAgency && <p>{labelAgency}: {itemAgency}</p> }
                                        { itemDirector && <p>{labelDirector}: {itemDirector}</p> }
                                        { itemOperator && <p>{labelOperator}: {itemOperator}</p> }
                                    </div>
                                }

                            </figcaption>
                        )}
                    </InView>
                </MediaQuery>
            </Fragment>

        );
    }
}

const mapStateToProps =(state) => ({
    translate: state.page.calculatedScroll
});

export default connect(mapStateToProps,null)(AnimatedPortfolioTextItem);