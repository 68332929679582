import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'
import page from "./PageReducer";
import player from "./PlayerReducer";
import portfolio from "./PortfolioReducer";

export default (history) => combineReducers({
    router: connectRouter(history),
    page,
    player,
    portfolio
});