import React from 'react';

const Falka = ({mainColor}) => {
    return (
        <svg version="1.1" id="Falka" xmlns="http://www.w3.org/2000/svg" width="438.897px" height="438.691px" viewBox="82.577 175.983 438.897 438.691">
        <title>falka</title>
    <path  fill={mainColor} d="M82.577,175.983h217.693c-0.114,122.332,98.872,221.642,221.204,221.927v216.765
	C279.23,614.504,82.862,418.229,82.577,175.983"/>
    </svg>
    );
};

export default Falka;