import React, {Component} from 'react';
import {connect} from "react-redux";

//actions
import {selectedVideo, togglePlayer} from "../../../../actions/playerActions";

//svg
import PlayerPlay from '../../../svg/PlayerPlay';
import SocialVimeo from '../../../svg/SocialVimeo';

//tools
import MediaQuery from 'react-responsive';
import { InView } from 'react-intersection-observer';

class OFirmie extends Component {

    constructor(props) {
        super(props);
        this.handlePlayer = this.handlePlayer.bind(this);
    }


    handlePlayer(id,title,subtitle,klient,agencja,rezyser,operat,panorama) {
        this.props.openPlayer();
        this.props.selectVideo({
            vimeoId:id,
            tytulFilmu:title,
            podtytulFilmu:subtitle,
            klient:klient,
            agencja:agencja,
            rezyser:rezyser,
            operator:operat,
            panorama: panorama
        });
    }

    render() {

        const reel = this.props.oFirmie,
            title = reel.tytul,
            id = reel.id,
            idmobile = reel.idmobile,
            reelokladka = reel.reel_okladka,
            reelid = reel.reel_vimeo_id,
            reelopis = reel.reel_krotki_opis,
            panorama = reel.reel_panorama;

        const opisHtml = {__html: reelopis };

        return (
            <section className="section ofirmie reel" id={id}>

                <div className="row left-xs middle-md" id={idmobile}>
                    <div className="col col-md-6">
                        <div className="row top-xs">
                            <MediaQuery maxWidth={768}>
                                <div className="col col-md-2 description">
                                    <h3>{title}</h3>
                                    <p dangerouslySetInnerHTML={opisHtml}/>
                                </div>
                            </MediaQuery>
                            <div className="col col-md-10 reel-cover"
                                 onClick={ () => this.handlePlayer( reelid, title,'','','','','',panorama) } >
                                <MediaQuery maxWidth={768}>
                                    <PlayerPlay />
                                    <SocialVimeo mainColor="#FFFFFF" />
                                </MediaQuery>
                                <img src={reelokladka} alt="Dynamo Reel" />

                            </div>
                            <MediaQuery minWidth={769}>
                                <div className="col col-md-2 description">
                                    <h3>{title}</h3>
                                    <p dangerouslySetInnerHTML={opisHtml}/>
                                </div>
                            </MediaQuery>
                        </div>

                    </div>
                    <div className="col col-md-6">
                        <MediaQuery minWidth={769}>
                            <InView>
                                {({ inView, ref }) => (
                                <figure ref={ref}
                                        className={inView ? 'line stretched':'line'}></figure>
                                )}
                            </InView>
                        </MediaQuery>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({
    openPlayer: () => dispatch(togglePlayer()),
    selectVideo: (id,title,subtitle,klient,agencja,rezyser,operat,panorama) => dispatch(selectedVideo(id,title,subtitle,klient,agencja,rezyser,operat,panorama))
});


export default connect(mapStateToProps,mapDispatchToProps)(OFirmie);